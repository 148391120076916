<template>
  <div class="pay">
    <HelloWorld :myName="3"></HelloWorld>
    <!-- <div class="top">
			<div class="topLine">
				<img src="../assets/index/top.png" @click="goHome" />
				<div class="title">{{$t('m.tiyan99')}}</div>
			</div>
		</div> -->
    <div class="main">
      <div class="payBox">
        <div class="name">
          <div class="mincheng">
            {{ $t("m.shangpin79") }}:{{ $t("m.ceshi80") }}
          </div>
          <div class="jine">
            <span>{{ $t("m.jine82") }}:</span>
            <div
              style="
                width: 100px;
                height: 35px;
                display: flex;
                align-items: center;
              "
            >
              <el-input v-model="demoVal.pay_amount"></el-input>
            </div>
            <span class="addSpan">{{ $t("m.yuan98") }}</span>
          </div>
        </div>
        <div class="number">
          {{ $t("m.dingdan81") }}:<span>{{ demoVal.pay_orderid }}</span>
        </div>
      </div>
      <div class="way">
        <div class="mode">{{ $t("m.zhifu83") }}</div>
        <div class="option">
          <div
            class="imgValBox"
            v-for="(item, index) in payType.list"
            v-on:click="addClass(index, $event, item.id)"
            v-bind:class="{ blue: index == current }"
          >
            <img :src="item.img_url" />
          </div>
        </div>
        <!-- 银行列表选项-无》》不显示 -->
        <div
          class="backListBox"
          v-if="payType.list && payType.list[bankName].bankcode != ''"
        >
          <div class="bank">{{ $t("m.bank174") }}</div>
          <div class="backList">
            <span
              v-if="payType.list[bankName].bankcode"
              v-for="(item, index) in payType.list[bankName].bankcode"
              v-on:click="addClass0(index, item)"
              v-bind:class="{ blue: index == current0 }"
              >{{ item }}</span
            >
            <span
              v-if="showAddTypeBank"
              v-for="(item, index) in payType.list[bankName].addbankcode"
              v-on:click="
                addClass0(index + payType.list[bankName].bankcode.length, item)
              "
              v-bind:class="{
                blue:
                  index + payType.list[bankName].bankcode.length == current0,
              }"
              >{{ item }}</span
            >
          </div>
        </div>
        <!-- 支付选项按钮 Web payment / Scan QR code to pay -->
        <div
          class="backListBox"
          v-if="payType.list && payType.list[bankName].pay_type_sub[0]"
        >
          <div class="" style="margin-top: 20px">{{ $t("m.leixing175") }}</div>
          <div class="backList">
            <span
              v-for="(item, index) in payType.list[bankName].pay_type_sub"
              v-on:click="addClass01(index, item)"
              v-bind:class="{ blue: index == current01 }"
              >{{ $t("m.wangyezhifu") }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="payment"
        v-if="payType.list && payType.list[bankName].pay_type_sub[0]"
      >
        {{ $t("m.xuyao91") }}:
        <span style="color: #ff006a; font-weight: bold">{{
          demoVal.pay_amount
        }}</span>
        {{ $t("m.yuan98") }}
        <div class="btn" @click="goPay">{{ $t("m.liji92") }}</div>
      </div>
    </div>
    <!-- <div class="bottom"> {{$t('m.Copyright')}}</div> -->
    <!-- 666 -->
    <!-- 支付类型页面1===跳转默默 -->
    <div class="cellphonePay" style="display: none">
      <div class="rollBox" v-if="payType.list">
        <img
          v-for="(item, index) in payType.list"
          v-on:click="addClass(index, $event, item.id)"
          v-bind:class="{ blue: index == current }"
          src="../assets/index/banner/12.png"
        />
      </div>
      <div class="code">
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div class="money">
        <div>
          <span class="mum">4000</span
          ><span
            style="flex: 1; text-align: left; margin-left: 5px"
            class="title"
            >RM</span
          ><span class="btn" @click="saveQrcode">{{ $t("m.baocun124") }}</span>
        </div>
        <div class="title" style="margin-top: 15px">
          {{ $t("m.chognzhi125") }}
        </div>
        <div style="margin-top: 10px">
          <div class="dingdan">
            <span class="mum" style="flex: 1; text-align: left">{{
              demoVal.pay_orderid
            }}</span>
          </div>
          <span
            class="btn copy-btn"
            v-clipboard:copy="demoVal.pay_orderid"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t("m.copy126") }}</span
          >
        </div>
      </div>
      <div class="mobileBtn">z和ifu</div>
      <!-- <div class="timeOut">{{$t('m.daoqi127')}}<span class="mum">4000</span></div> -->
      <div class="ishi">
        <div>1.{{ $t("m.one128") }}</div>
        <div>2.{{ $t("m.qr129") }}</div>
      </div>
      <!-- <div class="mobileBtn">z和ifu</div> -->
    </div>

    <!-- <payType1></payType1> -->
    <!-- <div v-html="dataForm"></div> -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
import payType1 from "@/components/module/payType1";
import { Loading } from "element-ui";
export default {
  name: "",
  props: {
    myName: Number,
  },
  components: {
    HelloWorld: HelloWorld,
    payType1: payType1,
  },
  data() {
    return {
      payNumber: "0.00",
      payType: "",
      current: 0,
      demoVal: {
        pay_amount: 10000,
      },
      demoId: "",
      current0: 0,
      keyNameBank: "",
      dataForm: "",
      pay_orderid: "",
      requestTime: "",
      current01: 0,
      payTypeNameVal: 1,
      bankName: 0,
      showAddTypeBank: false,
      bankIndex: "",
      datNum1: "",
      array: "",
      // Scan QR code to pay
      sqctp: "",
      langTwo: localStorage.getItem("lang"),
    };
  },
  mounted() {
    console.log(this.langTwo, "langTwo");
    this.getType();
    this.getOrderVal();
    // this.creatQrCode();
    this.geUelData();
    // console.log(this.geUelData())
    if (this.geUelData()) {
      this.postUrlData();
    }
    // this.geUelData()
  },
  methods: {
    // 获取url参数
    geUelData() {
      var url = window.location.href; //获取当前url
      // console.log(Request.QueryString.Count,"lll")
      // if ( Request.QueryString.Count > 0) {
      var dz_url = url.split("#")[0]; //获取#/之前的字符串
      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
      if (cs) {
        var cs_arr = cs.split("&"); //参数字符串分割为数组
      } else {
        var cs_arr = [];
      }

      var cs = {};
      var that = this;
      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象
        cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.pay_orderid = cs.cartId;
      // 后台时间戳  秒
      this.requestTime = cs.requestTime;
      // 当前时间
      const timestamp = Date.parse(new Date());
      // 当前时间  秒
      const timeData = (timestamp * 1) / 1000;
      console.log(timeData, that.requestTime);
      // 一天
      const oneData = 24 * 60 * 60;
      // const timeReally=this.requestTime*1+oneData
      // 相差
      // var time = timeData - timeReally
      if (timeData * 1 - that.requestTime * 1 >= oneData) {
        // 大于1天
        that.$router.push("/pay");
        console.log("p");
        // return false
      } else {
        // 小于一天
        console.log("p1");
        return true;
      }
      // this.pay_md5sign = cs.cartId;
      // }
    },
    // 传输urldata
    postUrlData() {
      // postUrlData
      var that = this;
      if (!that.pay_orderid) {
        return false;
      }
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("pay_orderid", that.pay_orderid);
      API.postUrlData(data)
        .then((res) => {
          if (res.data.code == 0) {
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goHome() {
      this.$router.push("/");
    },
    // 获取支付商类别
    getType() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("page", "1");
      data.append("pagesize", "200");
      API.getProduct(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.payType = res.data.data;
            // this.$message.success('注册成功');
            that.demoId = res.data.data.list[0].id;
            // alert(res.data.data.list[0].id)
            that.datNum1 = res.data.data.list[0];
            // that.array = that.datNum1.pay_type_sub.split(",");
            // console.log(that.array);
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addClass0(index, item) {
      this.current0 = index;
      let that = this;
      console.log(index);
      this.bankIndex = index;
      this.keyNameBank = item;
    },
    // 点击支付方式
    addClass01(index, item, key) {
      console.log(item.value);
      // console.log(index,item)
      // return
      this.current01 = index;
      var that = this;
      this.payTypeNameVal = item.value;
      if (index == 1) {
        that.sqctp = "Scan QR code to pay";
        this.showAddTypeBank = false;
        if (that.bankIndex <= 12) {
          return;
        } else {
          this.addClass0(0, "TCB");
        }
      } else {
        this.showAddTypeBank = true;
        that.sqctp = "";
      }
    },
    addClass: function (index, event, id) {
      this.demoId = id;
      this.current = index;
      var that = this;
      //获取点击对象
      var el = event.currentTarget;
      if (that.payType.list[index].pay_type_sub[0]) {
        that.payTypeNameVal = that.payType.list[index].pay_type_sub[0].value;
        that.current01 = 0;
        that.sqctp = "";
      } else {
        that.payTypeNameVal = "";
      }
      this.bankName = index;
    },
    // 获取订单信息
    getOrderVal(index) {
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else {
        // console.log(lang)
        data.append("lang", "en-Us");
      }

      API.getDemo(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.demoVal = res.data.data;
            if (index) {
              this.keyNameBank = res.data.data.bankcode[index];
            } else {
              this.keyNameBank = res.data.data.bankcode[0];
            }
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 发起支付postDemoOrderPay
    goPay() {
      var that = this;
      // console.log(that.payTypeNameVal)
      // return
      // if (that.sqctp == "Scan QR code to pay") {
      // 	console.log(that.payTypeNameVal)
      // 	// 跳转转账页面
      // 	// this.$router.push('/transfer')
      // 	// that.$router.path="/Transfer"
      // 	return

      // }
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else {
        // console.log(lang)
        data.append("lang", "en-Us");
      }
      data.append("pay_orderid", that.demoVal.pay_orderid);
      data.append("amount", that.demoVal.pay_amount);
      data.append("pay_bankcode", that.demoId);
      if (that.demoId == "925") {
        data.append("bankcode", that.keyNameBank);
      } else {
      }
      // if()
      data.append("pay_type", that.payTypeNameVal);
      API.postDemoOrderPay(data)
        .then((res) => {
          that.dataForm = res.data;
          console.log(res.data.data.url);
          //return;
          // 跳转url
          if (res.data.data.url) {
            window.location.href = res.data.data.url + "&lang=" + lang;
          }
          setTimeout(function () {
            that.getOrderVal(that.current0);
          }, 1000);

          // if (res.data.code == 0) {
          // 	// this.demoVal = res.data.data
          // 	console.log(res.data.data);
          // 	// this.$message.success(res.data.msg);
          // 	this.$message.success({
          // 		message: res.data.msg,
          // 		offset: 180
          // 	});
          // } else {
          // 	// console.log(res);
          // 	// this.$message.error(res.data.msg);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: "www.rg-pay.com",
        width: 200,
        height: 200,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 保存二维码
    saveQrcode() {
      //找到canvas标签
      const myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      //创建一个a标签节点
      const a = document.createElement("a");
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      //设置下载文件的名字
      a.download = "qrcode.png";
      //点击
      a.click();
      // Toast.success('保存成功');
      this.$toast.success("");
    },
    // 复制
    // 复制成功时的回调函数
    onCopy(e) {
      this.$toast.success("");
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$toast.fail("");
    },
  },
};
</script>

<style lang="less">
.pay {
  .dingdan {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
  }

  .mobileBtn {
    width: 19.6875rem;
    height: 2.8125rem;
    background: #1f2a68;
    border-radius: 10px;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
    margin: 0 auto;
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qrcode {
    width: 200px;
    height: 200px;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .cellphonePay {
    display: none;
  }

  .rollBox {
    height: 160px;
    white-space: nowrap;
    overflow: scroll;

    img {
      border: 2px solid #fff;
    }
  }

  .rollBox::-webkit-scrollbar {
    display: none;
  }

  .blue {
    border: 2px solid #1f2a68 !important;
  }

  .bottom {
    text-align: center;

    font-size: 14px;
    font-family: Helvetica;
    color: #6e7ea7;
    line-height: 28px;
  }

  .payment {
    width: 1100px;
    // height: 120px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    margin-top: 30px;

    font-size: 16px;
    font-family: Helvetica;
    color: #1f2a68;
    line-height: 28px;
    padding: 15px 0;

    .btn {
      width: 180px;
      height: 60px;
      background: #1f2a68;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 30px;
      font-size: 16px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ffffff;
      line-height: 28px;
      cursor: pointer;
    }
  }

  .way {
    width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 34px 30px 60px 30px;
    margin-top: 30px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Helvetica;
    color: #1f2a68;
    line-height: 28px;

    .option {
      display: flex;
      justify-content: space-around;
      margin-top: 48px;
      flex-wrap: wrap;

      img {
        // width: 80%;
        height: auto;
        // border-radius: 10px;
        // border: 2px solid #fff;
      }

      .imgValBox {
        width: 150px;
        height: 80px;
        border: 2px solid #e5e5e5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // z-index:10;
        margin-bottom: 30px;
        overflow: hidden;

        img {
          max-width: 80%;
        }
      }

      .imgValBox:hover {
        border: 2px solid #1f2a68;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }

  div {
    box-sizing: border-box;
  }

  .payBox {
    width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;

    border-radius: 10px;

    .name,
    .jine {
      display: flex;
    }

    .jine {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        margin: 0 10px;
      }
    }

    .name {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }

    .number {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }
  }

  .top {
    background-color: #fff;
    height: 120px;
    display: flex;
    align-items: center;

    .topLine {
      width: 1100px;
      display: flex;
      align-items: center;
      margin: 0 auto;

      div {
        flex: 1;
        text-align: right;
      }

      img {
        width: 45px;
        height: 49px;
        cursor: pointer;
      }
    }
  }

  .main {
    background: #f8f9ff;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .el-input__inner {
    height: 35px;
  }

  .backListBox {
    .bank {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }

    .backList {
      // text-align: center;
      span {
        display: inline-block;
        padding: 5px 20px;
        border: 1px solid #e5e5e5;
        margin-right: 10px;
        border-radius: 5px;
        margin-top: 10px;
      }

      span:hover {
        cursor: pointer;
        border: 1px solid #1f2a68;
      }
    }
  }
}
</style>
