<template>
	<div class="LoginAndRegister">
		<HelloWorld :myName="5"></HelloWorld>
		<div class="loginBox">
			<div class="form  ">
				<div class="regist">{{$t('m.shnahghu63')}}</div>
				<!-- <div class="regist">商户登陆</div> -->
				<!-- <div class="regist">代理登陆</div> -->
				<div class="inputBox">
					<div class="borderBox" :class="{ active:1==isActive,activeFalse:1==isFalse}">
						<input class="inputBox1" :placeholder="$t('m.shuru64')" @focus="myFunction(1)" @blur="onBlur(1)"
							v-model="name" />
					</div>
					<div class="borderBox1" :class="{ active:2==isActive}">
						<input class="inputBox1" :placeholder="$t('m.mima65')" @focus="myFunction(2)" @blur="onBlur(1)"
							v-model="password" type="password" />
					</div>
					<div class="borderBox1" :class="{ active:3==isActive}">
						<input class="inputBox1" :placeholder="$t('m.again66')" @focus="myFunction(3)" @blur="onBlur(1)"
							v-model="Repeat" type="password" />
					</div>
					<div class="borderBox1 borderBox2" :class="{ active:4==isActive}">
						<input class="inputBox1" :placeholder="$t('m.email67')" @focus="myFunction(4)" @blur="onBlur(1)"
							v-model="email" />
					</div>
				</div>
				<div class="btn" @click="register">{{$t('m.zhuce68')}}</div>
				<div class="tishi">{{$t('m.you69')}}
					<!-- <span style="color: #1f2a68;">--></span>
					<span @click="gologinAndRegister">{{$t('m.denlu70')}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { WOW } from "wowjs";
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      isActive: "",
      name: "", //账号
      password: "", //密码
      Repeat: "", //重复密码
      email: "", //邮箱
      isFalse: 0,
    };
  },
  created() {
    let that = this;
    // document.onkeydown = function(e) {
    // 	e = window.event || e;
    // 	if (e.keyCode == 13) {
    // 		that.register()
    // 	}
    // }
  },
  methods: {
    myFunction(val) {
      // console.log("11")
      this.isActive = val;
    },
    onBlur(val) {
      // console.log("11")
      this.isActive = 0;
    },
    // 注册
    register() {
      var that = this;
      if (this.name.trim() == "") {
        this.$message.error({
          message: that.$t("m.userName118"),
          offset: 180,
        });
        return;
      } else if (this.password.trim() == "") {
        this.$message.error({
          message: that.$t("m.password119"),
          offset: 180,
        });
        return;
      } else if (this.Repeat.trim() == "") {
        this.$message.error({
          message: that.$t("m.argin120"),
          offset: 180,
        });
        return;
      } else if (this.email.trim() == "") {
        this.$message.error({
          message: that.$t("m.email121"),
          offset: 180,
        });
        return;
      }
      if (this.password.trim() != this.Repeat.trim()) {
        this.$message.error({
          message: that.$t("m.errorTow122"),
          offset: 180,
        });
        return;
      }
      var verify = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      if (!verify.test(this.email.trim())) {
        this.$message.error({
          message: that.$t("m.errorMessage123"),
          offset: 180,
        });
        return;
      }

      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("username", this.name);
      data.append("password", this.password);
      data.append("confirmpassword", this.Repeat);
      data.append("email", this.email);
      API.postRegister(data)
        .then((res) => {
          if (res.data.code == 0) {
            // window.open(res.data)
            // console.log(res.data);
            this.$message.success(that.$t("m.shenhe151"));
            // this.$router.push('/loginAndRegister')
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 登陆页
    gologinAndRegister() {
      this.$router.push("/loginAndRegister");
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        live: false,
        // boxClass: ‘wow‘,
        // animateClass: ‘animated‘,
        offset: 300,
        mobile: true,
        live: true,
      });
      wow.init();
    });
  },
};
</script>

<style lang="less" scoped="scoped">
.loginBox {
  background: #f8f9ff;
  padding: 50px 0 150px 0;

  .active {
    border-color: #1f2a68 !important;
    z-index: 999999;
    border-top: 1px solid #1f2a68 !important;
  }

  .activeFalse {
    border-color: red !important;
  }

  .form {
    width: 750px;
    // height: 620px;
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 50px 125px 66px 125px;

    .inputBox {
      margin-bottom: 50px;
    }

    .tishi {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 22px;

      span {
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
        line-height: 28px;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .btn {
      width: 500px;
      height: 60px;
      background: #1f2a68;
      border-radius: 10px;
      font-size: 16px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ffffff;
      line-height: 28px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .regist {
      font-size: 35px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #1f2a68;
      line-height: 42px;
      text-align: center;
      margin-bottom: 62px;
    }

    .inputBox {
      // border: 1px solid #C1C8D6;
      box-sizing: border-box;
      border-radius: 10px;

      // overflow: hidden;
      .inputBox1 {
        border-radius: 10px 10px 0px 0px !important;
      }

      .borderBox {
        border: 1px solid #c1c8d6;
        border-radius: 10px 10px 0px 0px;
      }

      .borderBox1 {
        border-bottom: 1px solid #c1c8d6;
        border-left: 1px solid #c1c8d6;
        border-right: 1px solid #c1c8d6;
      }

      .borderBox2 {
        border-radius: 0px 0px 10px 10px;
        overflow: hidden;

        input {
          border-radius: 0px 0px 10px 10px;
        }
      }

      input {
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: none;
        padding: 0 34px;
        box-sizing: border-box;
      }

      .inputBox1:focus {
        outline: none;
      }
    }
  }
}
</style>
