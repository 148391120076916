<template>
	<div class="Box404">
		<HelloWorld :myName="0"></HelloWorld>
		<div class="msg">{{$t('m.wufa153')}}</div>
		<div class="title">
			<img src="../assets/404/404.png" />
		</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "Home",
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {
				aboutUs: {


				},
				url: this.apiUrl
			}
		},
	}
</script>

<style scoped="scoped" lang="less">
	.Box404 {
		.msg{
			text-align: center;
			font-size: 35px;
			font-family: Helvetica-Light, Helvetica;
			font-weight: 300;
			color: #1f2a68;
			line-height: 42px;
			margin-top: 84px;
		}
		.title {
			width: 550px;
			height: 360px;
			text-align: center;
			margin: 0 auto;
			margin-top: 25px;
			margin-bottom: 73px;
			img{
				width: 100%;
				height: auto;
			}
		}
	}
</style>
