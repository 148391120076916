<template>
	<div class="payType1">
		<HelloWorld :myName="3"></HelloWorld>
		<div class="payType">
			<div class="topTile"><img src="../../assets/index/banner/10.png" />
				<div v-for="item in 3">
					<div class="title">66666</div>
					<div class="num">66666</div>
				</div>
			</div>
			<!-- <div class="imgBox">
				<img v-for="item in 5" class="" src="../../assets/develop/mulu.png" />
			</div> -->
			<div class="bottom">
				<div class="tishi">555</div>
				<div class="inputBox">
					<input class="first" placeholder="55555" /><br />
					<input  class="two" placeholder="55555" />
				</div>
				<div class="btnBox">
					<div v-for="item in 2">按钮</div>
				</div>
				<div class="timeOut">
					<img src="../../assets/pay/time.png" /><div>46545656</div>
				</div>
				<div class="redTitle">11111111</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "payType1",
		props: {
			msg: String,
		},
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {

			}
		},
	}
</script>

<style lang="less" scoped="scoped">
	// @import './common/css/media.less';
	@import '../../common/css/payType1.less';
	div{
		box-sizing: border-box;
	}
	.inputBox{
		input{
			padding: 0 20px;
			box-sizing: border-box;
		}
		input:focus{
			border: 1px solid #1f2a68 !important;
			// background-color:yellow;
		}
		.first{
			border-bottom: none !important;
		}
	}
	.payType{
		max-width: 1200px;
		margin: 0 auto;
	}
	@media screen and (min-width: 1000px) {
	    body {
	      div{
			  text-align: center;
		  }
	    }
		.btnBox{
			max-width: 500px;
			margin: 0 auto;
		}
	}
	
</style>
