<template>
  <div class="develop">
    <HelloWorld :myName="3"></HelloWorld>
    <div class="topFixation">
      <div>
        <img
          src="../assets/develop/mulu.png"
          @click="showPopBox"
          style="width: 2.9375rem; height: 2.9375rem"
        />
      </div>
      <!-- <van-icon name="bars" @click="showPopBox" size="2rem" color="#1f2a68" /> -->
    </div>
    <div class="top">
      <div class="name">{{ $t("m.wendang150") }}</div>
      <div class="hint">API DOCUMENTATION</div>
    </div>
    <div class="developMian" ref="menu">
      <div class="developLeft" :class="{ is_top: isTop }">
        <div class="leftBox">
          <!-- API开发文档简介 -->
          <a
            href="#apidoc_"
            @click="onChangePoint('')"
            :class="active === '' ? 'active' : ''"
            >{{ $t("m.wandang130") }}</a
          >
        </div>
        <!-- 目录 -->
        <div class="leftBox" v-for="(item, index) in stepLists" :key="index">
          <a
            :href="'#apidoc_' + index"
            :class="active === index ? 'active' : ''"
            @click="onChangePoint(index)"
            >{{ item.title }}</a
          >
        </div>
      </div>

      <div class="developRight" :class="{ isRight: isTop }" id="apidoc_">
        <!-- API开发文档简介 -->
        <div class="title">{{ $t("m.wandang130") }}</div>
        <!-- 本文阅读对象 -->
        <div class="line">
          {{ $t("m.duixiang131") }}
        </div>
        <div
          v-for="(item, index) in stepLists"
          :key="index"
          :id="'apidoc_' + index"
        >
          <p class="title">{{ item.title }}</p>
          <p class="line" v-html="item.description"></p>
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-if="index == 0"
            :show-header="false"
          >
            <el-table-column prop="date" label=""> </el-table-column>
            <el-table-column prop="name" label=""> </el-table-column>
            <!-- <el-table-column prop="address" label=""> </el-table-column> -->
          </el-table>
          <el-table
            :data="serveData"
            border
            style="width: 100%"
            v-if="index == 3"
            :show-header="false"
          >
            <el-table-column prop="date" label=""> </el-table-column>
            <el-table-column prop="name" label=""> </el-table-column>
          </el-table>
          <!-- 目录5表格1 -->
          <el-table
            :data="payData"
            border
            style="width: 100%"
            v-if="index == 4"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="date" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="name" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="switch" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="describe" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <p class="line" v-if="item.headline2" v-html="item.headline2"></p>
          <!-- 目录5表格2 -->
          <el-table
            :data="parametersData"
            border
            style="width: 100%"
            v-if="index == 4"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="date" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="name" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="switch" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="describe" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <!-- 目录6表格1 -->
          <el-table
            :data="qingqiuData"
            border
            style="width: 100%"
            v-if="index == 5"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="date" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="name" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="switch" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="describe" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <p
            class="line"
            v-if="index == 5"
            v-html="$t('m.mulu6_2.fanhuicanshu268')"
          ></p>
          <!-- 目录6表格2 -->
          <el-table
            :data="returnParameter"
            border
            style="width: 100%"
            v-if="index == 5"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <!-- 目录7 通知参数： 标题 -->
          <p
            class="line"
            v-if="index == 6"
            v-html="$t('m.inform7_269.tongzhi4')"
          ></p>
          <!-- 目录7表格1 -->
          <el-table
            :data="InformTheParameters"
            border
            style="width: 100%"
            v-if="index == 6"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <!-- 目录7-最后提示 -->
          <p
            class="line"
            v-if="index == 6"
            v-html="$t('m.inform7_269.title4')"
          ></p>
          <!-- 目录8表格1 -->
          <el-table
            :data="parameter8"
            border
            style="width: 100%"
            v-if="index == 7"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <p
            class="line"
            v-if="index == 7"
            v-html="$t('m.CloseTheOrder8_270.title4')"
          ></p>
          <!-- 目录8表格2 -->
          <el-table
            :data="parameter8_2"
            border
            style="width: 100%"
            v-if="index == 7"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <!-- 目录9表格1-->
          <el-table
            :data="channel9_table1"
            border
            style="width: 100%"
            v-if="index == 8"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <p
            class="line"
            v-if="index == 8"
            v-html="$t('m.common_9.title2')"
          ></p>
          <!-- 目录9表格2-->
          <el-table
            :data="channel9_table2"
            border
            style="width: 100%"
            v-if="index == 8"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="column1" :label="$t('m.canshu214')">
            </el-table-column>
            <el-table-column prop="column2" :label="$t('m.canshuming215')">
            </el-table-column>
            <el-table-column prop="column3" :label="$t('m.bixu216')">
            </el-table-column>
            <el-table-column prop="column4" :label="$t('m.miaoshu217')">
            </el-table-column>
          </el-table>
          <!-- 目录10表格1-->
          <el-table
            :data="returnCodeTen"
            border
            style="width: 100%"
            v-if="index == 9"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column
              prop="column1"
              :label="$t('m.returnCode10.biaptou1')"
            >
            </el-table-column>
            <el-table-column
              prop="column2"
              :label="$t('m.returnCode10.biaptou2')"
            >
            </el-table-column>
            <el-table-column
              prop="column3"
              :label="$t('m.returnCode10.biaptou3')"
            >
            </el-table-column>
            <!-- <el-table-column prop="column4" :label="$t('m.miaoshu217')">
						</el-table-column> -->
          </el-table>
          <!-- 目录11表格1-->
          <p
            class="line"
            v-if="index == 10"
            v-html="$t('m.appendix11.title1')"
          ></p>
          <el-table
            :data="appendix11"
            border
            style="width: 100%"
            v-if="index == 10"
            :show-header="true"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column
              prop="column1"
              :label="$t('m.appendix11.headline1')"
            >
            </el-table-column>
            <el-table-column
              prop="column2"
              :label="$t('m.appendix11.headline2')"
            >
            </el-table-column>
            <el-table-column
              prop="column3"
              :label="$t('m.appendix11.headline3')"
            >
            </el-table-column>
            <!-- <el-table-column prop="column4" :label="$t('m.miaoshu217')">
						</el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
    <div>
      <van-popup
        v-model="showPop"
        position="right"
        :style="{ height: '100%', width: '40%' }"
      >
        <!-- <div class="addBox" :class="{ is_top: isTop }"> -->
        <div class="addBox">
          <div class="leftBox">
            <!-- API开发文档简介 -->
            <a
              href="#apidoc_"
              @click="onChangePoint('')"
              :class="active === '' ? 'active' : ''"
              >{{ $t("m.wandang130") }}</a
            >
          </div>
          <div class="leftBox" v-for="(item, index) in stepLists" :key="index">
            <a
              :href="'#apidoc_' + index"
              :class="active === index ? 'active' : ''"
              @click="onChangePoint(index)"
              >{{ item.title }}</a
            >
          </div>
        </div>
      </van-popup>
    </div>
    <!-- <van-popup v-model="showPop">内容</van-popup> -->
  </div>
</template>

<script>
import HelloWorld from "@/components/home/HelloWorld";
import * as API from "@/api/pageRequest/home"; //引入API
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      isTop: false,
      placeholder: false,
      active: "",
      appendix11:[],
      showPop: false,
    };
  },
  computed: {
    stepLists() {
      return [
        // 1.接口规范
        {
          title: this.$t("m.jiekou176"),
          description: this.$t("m.guizhe186"),
        },
        // 2.签名规范
        {
          title: this.$t("m.jianming177"),
          description:
            this.$t("m.jianming187") +
            "<br /><br />" +
            this.$t("m.er188") +
            "<br /><br />" +
            this.$t("m.san189") +
            "<br /><br />" +
            this.$t("m.juli190") +
            "<br /><br />" +
            this.$t("m.xianyou191") +
            "<br /><br />" +
            this.$t("m.username192") +
            "<br /><br />" +
            this.$t("m.password193") +
            "<br /><br />" +
            this.$t("m.timestamp194") +
            "<br /><br />" +
            this.$t("m.nonceStr195") +
            "<br /><br />" +
            this.$t("m.secret_key196") +
            "<br /><br />" +
            this.$t("m.pinjie197") +
            "<br /><br />" +
            this.$t("m.juli198") +
            "<br /><br />" +
            this.$t("m.ziduan199") +
            "<br /><br />" +
            this.$t("m.pinjie200") +
            "<br /><br />" +
            this.$t("m.md5201") +
            "<br /><br />" +
            this.$t("m.Sign202"),
        },
        // 3.请求规范
        {
          title: this.$t("m.qingqiu178"),
          description:
            this.$t("m.guifan203") + "<br /><br />" + this.$t("m.guifen204"),
        },
        // 4.服务器接口列表
        {
          title: this.$t("m.jiekou179"),
          description: "",
        },
        // 5.创建支付订单
        {
          title: this.$t("m.chuangjian180"),
          description:
            this.$t("m.dizhi212") + "<br /><br />" + this.$t("m.qingqiu213"),
          headline2: this.$t("m.fenhui246"),
        },
        // 6.查询支付订单
        {
          title: this.$t("m.chaxun181"),
          description:
            this.$t("m.fukuan255") +
            "<br /><br />" +
            this.$t("m.jikeou256") +
            "<br /><br />" +
            this.$t("m.qingqiu213"),
        },
        // 7.支付结果异步通知
        {
          title: this.$t("m.zhifu182"),
          description:
            this.$t("m.inform7_269.title1") +
            "<br /><br />" +
            this.$t("m.inform7_269.title2") +
            "<br /><br />" +
            this.$t("m.inform7_269.title3"),
        },
        // 8.关闭订单
        {
          title: this.$t("m.guanbi183"),
          description:
            this.$t("m.CloseTheOrder8_270.title1") +
            "<br /><br />" +
            this.$t("m.CloseTheOrder8_270.title2") +
            "<br /><br />" +
            this.$t("m.CloseTheOrder8_270.title3"),
        },
        //9.获取支付渠道列表
        {
          title: this.$t("m.gongong184"),
          description: this.$t("m.common_9.title1"),
        },
        // 10.公共返回码
        {
          title: this.$t("m.fulu185"),
          description: "",
        },
        // 11.附录
        {
          title: this.$t("m.add11fulu"),
          description: "",
        },
      ];
    },
    // 目录1表格1
    tableData() {
      return [
        {
          date: this.$t("m.specification.table1.column1.val1"),
          name: this.$t("m.specification.table1.column2.val1"),
        },
        {
          date: this.$t("m.specification.table1.column1.val2"),
          name: this.$t("m.specification.table1.column2.val2"),
        },
        {
          date: this.$t("m.specification.table1.column1.val3"),
          name: this.$t("m.specification.table1.column2.val3"),
        },
        {
          date: this.$t("m.specification.table1.column1.val4"),
          name: this.$t("m.specification.table1.column2.val4"),
        },
        {
          date: this.$t("m.specification.table1.column1.val5"),
          name: this.$t("m.specification.table1.column2.val5"),
        },
      ];
    },
    // 4.服务器接口列表
    serveData() {
      return [
        {
          date: this.$t("m.fuwu205"),
          name: this.$t("m.dizhi206"),
        },
        {
          date: this.$t("m.wangguan207"),
          name: "https://api.rg-pay.com/v1/pay",
        },
        {
          date: this.$t("m.banban208"),
          name: "v1",
        },
        {
          date: this.$t("m.guifan209"),
          name: this.$t("m.fanli210") + "      " + this.$t("m.fanli211"),
        },
      ];
    },
    // 5.创建支付订单 表格
    payData() {
      return [
        {
          // 1
          date: "pay_memberid",
          name: this.$t("m.lie218"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.suoqu233"),
        },
        {
          // 2
          date: "pay_orderid",
          name: this.$t("m.lie219"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.ru234"),
        },
        {
          // 3
          date: "pay_applydate",
          name: this.$t("m.lie220"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.ru235"),
        },
        {
          // 4
          date: "pay_bankcode",
          name: this.$t("m.lie221"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.juti236"),
        },
        {
          // 5
          date: "pay_notifyurl",
          name: this.$t("m.lie222"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.zhifu237"),
        },
        {
          // 6
          date: "pay_callbackurl",
          name: this.$t("m.lie223"),
          switch: this.$t("m.no232"),
          describe: this.$t("m.chuandi238"),
        },
        {
          // 7
          date: "pay_amount",
          name: this.$t("m.lie224"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.dun239"),
        },
        {
          // 8
          date: "timestamp",
          name: this.$t("m.lie225"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.danweimiao240"),
        },
        {
          // 9
          date: "nonceStr",
          name: this.$t("m.lie226"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.zifuchuan241"),
        },
        {
          // 10
          date: "pay_productname",
          name: this.$t("m.lie227"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.chognzhi242"),
        },
        {
          // 11
          date: "pay_type",
          name: this.$t("m.lie228"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.cuandi243"),
        },
        {
          // 12
          date: "content_type",
          name: this.$t("m.lie229"),
          switch: this.$t("m.no232"),
          describe: this.$t("m.huidiao244"),
          // describe: [
          // 	"平均耗时2"+"\n",
          // 	"平均耗时2"+"\n",
          // 	"带宽2"
          // ],
        },
        {
          // 13
          date: "pay_md5sign",
          name: this.$t("m.lie230"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.qianming245"),
        },
      ];
    },
    // 5.返回参数表格
    parametersData() {
      return [
        {
          // 1
          date: "code",
          name: this.$t("m.fanhuima247"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.daibiao250"),
        },
        {
          // 2
          date: "msg",
          name: this.$t("m.fanhuixiaoxi248"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.success251"),
        },
        {
          // 3
          date: "data ",
          name: this.$t("m.fanhuishuju249"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.successdata252"),
        },
        {
          // 4
          date: "url",
          name: this.$t("m.fanhuishuju249"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.dingdan253"),
        },
        {
          // 5
          date: "pay_orderid",
          name: this.$t("m.fanhuishuju249"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.pingtai254"),
        },
      ];
    },
    // 目录6表格1
    qingqiuData() {
      return [
        {
          // 1
          date: "pay_memberid ",
          name: this.$t("m.mulu6.canshuming.mulu6shanghu257"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.mulu6.miaoshu.suoqu262"),
        },
        {
          // 2
          date: "pay_orderid ",
          name: this.$t("m.mulu6.canshuming.mulu6dingdanhao258"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.mulu6.miaoshu.li263"),
        },
        {
          // 3
          date: "nonce_str ",
          name: this.$t("m.mulu6.canshuming.mulu6sunjicuan259"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.mulu6.miaoshu.zifucuan264"),
        },
        {
          // 4
          date: "timestamp",
          name: this.$t("m.mulu6.canshuming.mulu6shijiancuo260"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.mulu6.miaoshu.shijiancuo265"),
        },
        {
          // 5
          date: "pay_md5sign ",
          name: this.$t("m.mulu6.canshuming.mulu6qianming261"),
          switch: this.$t("m.yes231"),
          describe: this.$t("m.mulu6.miaoshu.suanfa267"),
        },
      ];
    },
    // 目录6表格2返回参数：
    returnParameter() {
      return [
        {
          // 1
          column1: "code ",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data1"),
        },
        {
          // 2
          column1: "msg ",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data2"),
        },
        {
          // 3
          column1: "data ",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data3"),
          column3: this.$t("m.no232"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data3"),
        },
        {
          // 4
          column1: "memberid",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data4"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data4"),
        },
        {
          // 5
          column1: "orderid",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data5"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data5"),
        },
        {
          // 6
          column1: "amount",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data6"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data6"),
        },
        {
          // 7
          column1: "time_end",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data7"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data7"),
        },
        {
          //8
          column1: "transaction_id",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data8"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data8"),
        },
        {
          // 9
          column1: "pay_type",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data9"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data9"),
        },
        {
          // 10
          column1: "returncode",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data10"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data10"),
        },
        {
          // 11
          column1: "trade_state",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data11"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data11"),
        },
        {
          // 12
          column1: "nonce_str ",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data12"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data12"),
        },
        {
          // 13
          column1: "timestamp ",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data13"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data13"),
        },
        {
          // 14
          column1: "sign",
          column2: this.$t("m.mulu6_2.canshufanhui.column2.data14"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.mulu6_2.canshufanhui.column4.data14"),
        },
      ];
    },
    // 目录7表格1-通知参数
    InformTheParameters() {
      return [
        {
          // 1
          column1: "memberid",
          column2: this.$t("m.inform7_269.column2.val1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val1"),
        },
        {
          // 2
          column1: "orderid",
          column2: this.$t("m.inform7_269.column2.val2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val2"),
        },
        {
          // 3
          column1: "amount",
          column2: this.$t("m.inform7_269.column2.val3"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val3"),
        },
        {
          // 4
          column1: "time_end",
          column2: this.$t("m.inform7_269.column2.val4"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val4"),
        },
        {
          // 5
          column1: "transaction_id",
          column2: this.$t("m.inform7_269.column2.val5"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val5"),
        },
        {
          // 6
          column1: "pay_type",
          column2: this.$t("m.inform7_269.column2.val6"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val6"),
        },
        {
          // 7
          column1: "returncode",
          column2: this.$t("m.inform7_269.column2.val7"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val7"),
        },
        {
          // 8
          column1: "trade_state",
          column2: this.$t("m.inform7_269.column2.val8"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val8"),
        },
        {
          // 9
          column1: "nonce_str ",
          column2: this.$t("m.inform7_269.column2.val9"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val9"),
        },
        {
          // 10
          column1: "timestamp ",
          column2: this.$t("m.inform7_269.column2.val10"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val10"),
        },
        {
          // 11
          column1: "sign",
          column2: this.$t("m.inform7_269.column2.val11"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.inform7_269.column4.val11"),
        },
      ];
    },
    // 目录8表格1
    parameter8() {
      return [
        {
          column1: "pay_memberid",
          column2: this.$t("m.CloseTheOrder8_270.table.column2.val1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.table.column4.val1"),
        },
        {
          column1: "pay_orderid",
          column2: this.$t("m.CloseTheOrder8_270.table.column2.val2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.table.column4.val2"),
        },
        {
          column1: "nonce_str ",
          column2: this.$t("m.CloseTheOrder8_270.table.column2.val3"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.table.column4.val3"),
        },
        {
          column1: "timestamp ",
          column2: this.$t("m.CloseTheOrder8_270.table.column2.val4"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.table.column4.val4"),
        },
        {
          column1: "pay_md5sign",
          column2: this.$t("m.CloseTheOrder8_270.table.column2.val5"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.table.column4.val5"),
        },
      ];
    },
    // 目录8表格2
    parameter8_2() {
      return [
        {
          column1: "code ",
          column2: this.$t("m.CloseTheOrder8_270.tableTwo.column2.val1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.tableTwo.column4.val1"),
        },
        {
          column1: "msg ",
          column2: this.$t("m.CloseTheOrder8_270.tableTwo.column2.val2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.CloseTheOrder8_270.tableTwo.column4.val2"),
        },
      ];
    },
    // 目录9表格1
    channel9_table1() {
      return [
        {
          column1: "pay_memberid ",
          column2: this.$t("m.common_9.table1.column2.val1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table1.column4.val1"),
        },
        {
          column1: "nonce_str ",
          column2: this.$t("m.common_9.table1.column2.val2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table1.column4.val2"),
        },
        {
          column1: "timestamp ",
          column2: this.$t("m.common_9.table1.column2.val3"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table1.column4.val3"),
        },
        {
          column1: "pay_md5sign ",
          column2: this.$t("m.common_9.table1.column2.val4"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table1.column4.val4"),
        },
      ];
    },
    // 目录9表格2
    channel9_table2() {
      return [
        {
          // 1
          column1: "code ",
          column2: this.$t("m.common_9.table2.column2.val1"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val1"),
        },
        {
          // 2
          column1: "msg ",
          column2: this.$t("m.common_9.table2.column2.val2"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val2"),
        },
        {
          // 3
          column1: "data",
          column2: this.$t("m.common_9.table2.column2.val3"),
          column3: this.$t("m.no232"),
          column4: this.$t("m.common_9.table2.column4.val3"),
        },
        {
          // 4
          column1: "count",
          column2: this.$t("m.common_9.table2.column2.val4"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val4"),
        },
        {
          // 5
          column1: "list",
          column2: this.$t("m.common_9.table2.column2.val5"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val5"),
        },
        {
          // 6
          column1: "id",
          column2: this.$t("m.common_9.table2.column2.val6"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val6"),
        },
        {
          // 7
          column1: "name",
          column2: this.$t("m.common_9.table2.column2.val7"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val7"),
        },
        {
          // 8
          column1: "img_ur",
          column2: this.$t("m.common_9.table2.column2.val8"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val8"),
        },
        {
          // 9
          column1: "pay_type_sub",
          column2: this.$t("m.common_9.table2.column2.val9"),
          column3: this.$t("m.yes231"),
          column4: this.$t("m.common_9.table2.column4.val9"),
        },
        {
          // 10
          column1: "bankcode",
          column2: this.$t("m.common_9.table2.column2.val10"),
          column3: this.$t("m.no232"),
          column4: this.$t("m.common_9.table2.column4.val10"),
        },
      ];
    },
    // 目录10-表格1
    returnCodeTen() {
      return [
        {
          column1: this.$t("m.returnCode10.table1.column1.val1"),
          column2: this.$t("m.returnCode10.table1.column2.val1"),
          column3: "",
        },
        {
          column1: this.$t("m.returnCode10.table1.column1.val2"),
          column2: this.$t("m.returnCode10.table1.column2.val2"),
          column3: "",
        },
        {
          column1: this.$t("m.returnCode10.table1.column1.val3"),
          column2: this.$t("m.returnCode10.table1.column2.val3"),
          column3: "",
        },
      ];
    },
    // 目录11-表格1
    // appendix11() {
    //   return [
    //     {
    //       // 1
    //       column1: this.$t("m.appendix11.table1.column1.val1"),
    //       column2: "Vietcombank",
    //       column3: "",
    //     },
    //     {
    //       // 2
    //       column1: "927",
    //       column2: "VietinBank",
    //       column3: "",
    //     },
    //     {
    //       //3
    //       column1: "928",
    //       column2: "Techcombank",
    //       column3: "",
    //     },
    //     {
    //       // 4
    //       column1: "929",
    //       column2: "Agribank",
    //       column3: "",
    //     },
    //     {
    //       // 5
    //       column1: "930",
    //       column2: "MBBank",
    //       column3: "",
    //     },
    //     {
    //       // 6
    //       column1: "931",
    //       column2: "VPBank",
    //       column3: "",
    //     },
    //     {
    //       // 7
    //       column1: "932",
    //       column2: "ACB",
    //       column3: "",
    //     },
    //     {
    //       //8
    //       column1: "933",
    //       column2: "BIDV",
    //       column3: "",
    //     },
    //     {
    //       // 9
    //       column1: "934",
    //       column2: "HDBank",
    //       column3: "",
    //     },
    //     {
    //       // 10
    //       column1: "935",
    //       column2: "VIB",
    //       column3: "",
    //     },
    //     {
    //       // 11
    //       column1: "936",
    //       column2: "Sacombank",
    //       column3: "",
    //     },
    //     {
    //       // 12
    //       column1: "937",
    //       column2: "TPBank",
    //       column3: "",
    //     },
    //     {
    //       // 13
    //       column1: "938",
    //       column2: "Eximbank",
    //       column3: "",
    //     },
    //     {
    //       // 14
    //       column1: "939",
    //       column2: "MaritimeBank",
    //       column3: "",
    //     },
    //     {
    //       // 15
    //       column1: "940",
    //       column2: "DongABank",
    //       column3: "",
    //     },
    //     {
    //       // 16
    //       column1: "941",
    //       column2: "OCB",
    //       column3: "",
    //     },
    //     {
    //       // 17
    //       column1: "942",
    //       column2: "SHB",
    //       column3: "",
    //     },
    //     {
    //       // 18
    //       column1: "943",
    //       column2: "LienVietPostBank",
    //       column3: "",
    //     },
    //     {
    //       // 19
    //       column1: "944",
    //       column2: "SCBBank",
    //       column3: "",
    //     },
    //     {
    //       // 20
    //       column1: "945",
    //       column2: "OceanBank",
    //       column3: "",
    //     },
    //   ];
    // },
  },
  methods: {
    topMenu() {
      var menu = this.$refs.menu;
      window.addEventListener("scroll", () => {
        // 由于使用递归offsetTop,parentElement,offsetParent等方法都会遇到准确定位父元素的问题，所以使用如下方法
        if (menu.getBoundingClientRect().top <= 0) {
          this.isTop = true;
          this.placeholder = true;
        } else {
          this.isTop = false;
          this.placeholder = false;
        }
      });
    },
    getProductAll() {
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      API.getProduct(data)
        .then((res) => {
          if (res.data.code == 0) {
            let appendix11 = res.data.data.list;
            let arryaList = [];
            for (let i in appendix11) {
              arryaList.push({
                column1: appendix11[i].id,
                column2: appendix11[i].name,
                column3: "",
              });
            }
            this.appendix11 = arryaList;
            // this.$message.success('注册成功');
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 点击描点事件
     */
    onChangePoint(index) {
      let _this = this;
      _this.active = index;
      //console.log(_this.active);
      let _dom = _this.$el.querySelector("#apidoc_" + index);
      setTimeout(() => {
        // 让描点滚动到指定的位置
        document.documentElement.scrollTop = _dom.offsetTop - 80;
      }, 10);
    },
    showPopBox() {
      this.showPop = true;
    },
    scrollToTop() {
      var _this = this;
      _this.active = "";
      // 获取左边按钮节点的数量
      let parentDom = _this.$el.querySelector(".developLeft");
      let index = "";
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // 根据右边往上滚动的位置已经到达离顶部一定的高度时则切换左边菜单选中样式
      for (let i = 0; i < parentDom.children.length; ++i) {
        let _dom = _this.$el.querySelector("#apidoc_" + i);
        if (_dom && _dom.offsetTop && _dom.offsetTop - scrollTop < 300) {
          index = i;
        }
      }
      _this.active = index;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.topMenu();
      this.getProductAll(); //获取银行
      // 当页面刷新时保持描点位置
      if (window.location.hash) {
        let hashInfo = window.location.hash.split("_");
        let active = hashInfo[1] !== "" ? parseInt(hashInfo[1]) : "";
        this.onChangePoint(active);
      }
    });
    window.addEventListener("scroll", this.scrollToTop);
  },
  activated() {
    this.$nextTick(() => {
      var menu = this.$refs.menu;
      if (menu.getBoundingClientRect().top <= 0) {
        this.isTop = true;
        this.placeholder = true;
      } else {
        this.isTop = false;
        this.placeholder = false;
      }
    });
  },
};
</script>

<style lang="less" scoped="scoped">
.develop {
  p,
  div,
  tr,
  .el-table td div {
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    -ms-user-select: text;
  }

  .addBox {
    a {
      display: block;
      padding: 0.9375rem;
    }
  }

  .leftBox a:hover,
  .leftBox .active,
  .addBox .active {
    background-color: #1f2a68;
    color: #ffffff;
  }

  .topFixation {
    display: none;
    position: fixed;
    bottom: 12.25rem;
    right: 0.9375rem;
    z-index: 99;
  }

  // margin-bottom: 100px;
  .is_top {
    position: fixed;
    top: 80px;
    z-index: 999;
  }

  .isRight {
    margin-left: 304px;
  }

  div {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  .developMian {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding: 50px 0;

    // div::-webkit-scrollbar {
    //   width: 0;
    // }
    .developLeft {
      width: 235px;
      margin-right: 69px;
      overflow-y: scroll;
      max-height: calc(100% - 200px);
      z-index: -1;
      .leftBox a {
        padding: 17px 15px;
        background: #f8f9ff;
        word-wrap: break-word;
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        /*font-weight: bold;*/
        color: #1f2a68;
        line-height: 28px;
        cursor: pointer;
        display: block;
      }

      .leftBox a:hover,
      .leftBox .active,
      .addBox .active {
        background-color: #1f2a68;
        color: #ffffff;
      }
    }

    .developRight {
      flex: 1;
      min-height: 250px;

      // height: 100vh;
      // overflow: scroll;
      .title {
        font-size: 35px;
        font-family: Helvetica-Light, Helvetica;
        font-weight: 300;
        color: #1f2a68;
        line-height: 42px;
        width: 750px;
        border-bottom: 1px solid #6e7ea7;
        padding-bottom: 30px;
      }

      .line {
        width: 750px;
        font-size: 16px;
        font-family: Helvetica;
        color: #1f2a68;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }

  .el-table {
    margin-bottom: 10px;
  }

  .top {
    width: 100%;
    height: 200px;
    background-image: url(../assets/download/bg.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .name {
      font-size: 35px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #ffffff;
      line-height: 42px;
    }

    .hint {
      font-size: 20px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 2px;
    }
  }
}
</style>
