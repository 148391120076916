<template>
	<div class=" codeTimeout">
		<HelloWorld :myName="3"></HelloWorld>
		<div class="mainBox">
			<div class="imgBox">
				<img src="../../assets/pay/tenant.png" />
			</div>
			<div class="title allDiv">{{$t('m.jine162')}}</div>
			<div class="num allDiv">VDN 55555</div>
			<div class="title allDiv">{{$t('m.shijian165')}}</div>
			<div class="time allDiv">12.00</div>
			<div class="title allDiv">{{$t('m.daima166')}}</div>
			<div class="time allDiv">LANCID60A1D7ED49607LANCID60A1D7ED49607LANCID60A1D7ED49607LANCID60A1D7ED49607</div>
		</div>
		<!-- <div class="bgLine" @click="changeShow">22</div> -->
		<div class="wait mainBox" v-if="isShow">
			<div class="lider val">{{$t('m.jieguo163')}}</div>
			<div class="imgBox">
				<img src="../../assets/pay/ms.png" />
			</div>
			<div class=" val time allDiv" style="margin-top: 13px;">{{$t('m.xinxi164')}}</div>
			<div class="hint">{{$t('m.beizhu159')}}</div>
		</div>
		<div class="import mainBox" v-else>
			<div class="time allDiv">{{$t('m.timeOut154')}}<!-- <span>222</span>231321 --></div>
			<div class="title allDiv">{{$t('m.shuru155')}}<!-- <span>222</span> --></div>
			<div class="inputBox">
				<el-input v-model="input" :placeholder="$t('m.yanzheng157')"></el-input>
			</div>
			<div class="tishi">{{$t('m.miao156')}}</div>
			<div class="btn">
				{{$t('m.xiayibu158')}}
				<!-- <div></div> -->
			</div>
			<div class="footerTishi">{{$t('m.beizhu159')}}</div>
		</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "payType1",
		props: {
			msg: String,
		},
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {
				input: '',
				isShow:true
			}
		},
		methods:{
			changeShow(){
				this.isShow=!this.isShow
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	@media screen and (min-width: 1000px) {
	    body {
	      div{
			  text-align: center;
		  }
	    }
		.btnBox{
			max-width: 500px;
			margin: 0 auto;
		}
	}
	
	.mainBox {
		text-overflow: ellipsis;

		word-wrap: break-word;
		padding: 0.9375rem;

		.imgBox {
			display: flex;
			justify-content: center;

			img {
				width: 20rem;
				height: auto;
			}
		}

		.title {
			font-size: 12px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 14px;
		}

		.num {
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #FF006A;
			line-height: 19px;
		}

		.allDiv {
			margin-top: 5px;
		}

		.time {
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			line-height: 19px;
		}

		.lider {
			font-size: 18px;
			font-family: Helvetica-Light, Helvetica;
			font-weight: 300;
			color: #1f2a68;
			line-height: 20px;
			margin-top: 30px;


		}

		.val {
			text-align: center;
		}
	}

	.wait {
		.imgBox {
			img {
				width: 104px;
				height: auto;
				margin-top: 13px;
			}
		}

		.hint {
			font-size: 12px;
			font-family: Helvetica;
			color: #CD0F5E;
			line-height: 14px;
			margin-top: 15px;
		}
	}

	.bgLine {
		margin-top: 30px;
		width: 100%;
		height: 94px;
		background-color: #F4F9FC;
	}

	.import {
		text-align: center;
		background-color: #F8F9FF;

		.time {
			span {
				font-size: 18px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #FF006A;
				line-height: 28px;
				margin: 0 5px;
			}
		}

		.title {
			span {
				font-size: 12px;
				font-family: Helvetica;
				color: #FF006A;
				line-height: 14px;
				margin: 0 5px;
			}
		}

		.inputBox {
			margin-top: 20px;
			margin-bottom: 15px;
		}

		.tishi {
			font-size: 12px;
			font-family: Helvetica;
			color: #FF006A;
			line-height: 14px;
		}

		.btn {
			width: 100%;
			background: #1f2a68;
			border-radius: 10px;
			padding: 9px 0;
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #FFFFFF;
			// margin: 0 0.9375rem;
			line-height: 28px;
			margin-top: 15px;
		}

		.footerTishi {
			font-size: 12px;
			font-family: Helvetica;
			color: #CD0F5E;
			line-height: 14px;
			margin-top: 30px;
			text-align: left;
		}
	}
</style>
