<template>
  <div class="product">
    <HelloWorld :myName="2"></HelloWorld>

    <productTop></productTop>
    <div class="title1 name">{{ $t("m.shoukuan5") }}</div>
    <div class="title1 val anchor">AGGREGATE RECEIPTS</div>
    <div class="allBox zqPrduct">
      <div class="imgBox wow slideInUp">
        <img src="../assets/product/code.png" />
      </div>
      <div class="essay wow slideInUp">
        <div class="headline">{{ $t("m.shoujiapp6") }}</div>
        <div class="content">
          {{ $t("m.leibie7") }}
          <span v-for="(item, index) in backList">{{ item.name }},</span>
        </div>
        <div class="listBox wow slideInUp">
          <div
            class="view"
            v-bind:class="{ activeView: 1 == activeView }"
            @mouseenter="StopRoll(1)"
            @mouseleave="UpRoll()"
          >
            <img src="../assets/product/bg1.png" />
            <div>{{ $t("m.shouji8") }}</div>
          </div>
          <div
            class="view"
            v-bind:class="{ activeView: 2 == activeView }"
            @mouseenter="StopRoll(2)"
            @mouseleave="UpRoll()"
          >
            <img src="../assets/product/bg2.png" />
            <div>{{ $t("m.gongzhong9") }}</div>
          </div>
          <div
            class="view"
            v-bind:class="{ activeView: 3 == activeView }"
            @mouseenter="StopRoll(3)"
            @mouseleave="UpRoll()"
          >
            <img src="../assets/product/bg3.png" />
            <div>{{ $t("m.wangye10") }}</div>
          </div>
          <div
            class="view"
            v-bind:class="{ activeView: 4 == activeView }"
            @mouseenter="StopRoll(4)"
            @mouseleave="UpRoll()"
          >
            <img src="../assets/product/bg4.png" />
            <div>{{ $t("m.xianxia11") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="details">
      <div class="title1 name anchor wow slideInUp">
        {{ $t("m.zixitong12") }}
      </div>
      <div class="title1 val">MERCHANT SYSTEM</div>
      <div class="title1 body wow slideInUp">
        {{ $t("m.shanghu13") }}
      </div>
      <div class="banner">
        <div class="bannerLeft">
          <div
            @click="idActive(1)"
            v-bind:class="{ active: 1 == activeBanner }"
          >
            <img v-if="activeBanner == 1" src="../assets/product/a1.png" />
            <img v-else src="../assets/product/b1.png" />
            <span>{{ $t("m.shnaghu14") }}</span>
          </div>
          <div
            @click="idActive(2)"
            v-bind:class="{ active: 2 == activeBanner }"
          >
            <img v-if="activeBanner == 2" src="../assets/product/a2.png" />
            <img v-else src="../assets/product/b2.png" />
            <span>{{ $t("m.jiaoyi15") }}</span>
          </div>
          <div
            @click="idActive(3)"
            v-bind:class="{ active: 3 == activeBanner }"
          >
            <img v-if="activeBanner == 3" src="../assets/product/a3.png" />
            <img v-else src="../assets/product/b3.png" />
            <span>{{ $t("m.caiwu16") }}</span>
          </div>
          <div
            @click="idActive(4)"
            v-bind:class="{ active: 4 == activeBanner }"
          >
            <img v-if="activeBanner == 4" src="../assets/product/a4.png" />
            <img v-else src="../assets/product/b4.png" />
            <span>{{ $t("m.fenrun17") }}</span>
          </div>
          <div
            @click="idActive(5)"
            v-bind:class="{ active: 5 == activeBanner }"
          >
            <img v-if="activeBanner == 5" src="../assets/product/a5.png" />
            <img v-else src="../assets/product/b5.png" />
            <span>{{ $t("m.zijin18") }}</span>
          </div>
        </div>
        <div class="bannerRight">
          <div class="imgBox">
            <img v-if="activeBanner == 1" src="../assets/product/ren1.png" />
            <img v-if="activeBanner == 2" src="../assets/product/fada.png" />
            <img v-if="activeBanner == 3" src="../assets/product/jt.png" />
            <img v-if="activeBanner == 4" src="../assets/product/dg.png" />
            <img v-if="activeBanner == 5" src="../assets/product/hd.png" />
          </div>
          <div class="titleBox">
            <div class="top" v-if="activeBanner == 1">
              {{ $t("m.zixitong12") }}
            </div>
            <div class="top" v-if="activeBanner == 2">
              {{ $t("m.daifu21") }}
            </div>
            <div class="top" v-if="activeBanner == 3">
              {{ $t("m.jiaoyi23") }}
            </div>
            <div class="top" v-if="activeBanner == 4">
              {{ $t("m.caiwu26") }}
            </div>
            <div class="top" v-if="activeBanner == 5">
              {{ $t("m.jiekou29") }}
            </div>
            <div class="bannerBox">
              <span v-if="activeBanner == 1">{{ $t("m.shanghu19") }}</span>
              <span v-if="activeBanner == 2">{{ $t("m.zhuliu22") }}</span>
              <span v-if="activeBanner == 3"
                >{{ $t("m.fangwei24") }}<br />{{ $t("m.fenxi25") }}</span
              >
              <span v-if="activeBanner == 4"
                >{{ $t("m.shuju27") }}<br />{{ $t("m.duixhang28") }}</span
              >
              <span v-if="activeBanner == 5"
                >{{ $t("m.fuwu30") }}<br />{{ $t("m.qingsuan31") }}</span
              >
              <div style="display: flex; flex-direction: column; z-index: 10">
                <div style="flex: 1"></div>
                <div class="btnBox">
                  <el-button plain @click="goRegist">{{
                    $t("m.liaojie20")
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="allBox anchor">
      <div class="title1 name wow slideInUp">{{ $t("m.daifu32") }}</div>
      <div class="title1 val">PAYMENT SYSTEM</div>
      <div class="matter">
        <div class="column">
          <div class="matterName">{{ $t("m.daifu32") }}</div>
          <div class="matterVal">
            {{ $t("m.ruliu33") }}<br />{{ $t("m.changjign34") }}
            <span v-for="(item, index) in backList">{{ item.name }},</span>...
          </div>
          <div class="btnBox">
            <a :href="'mailto:' + url" id="sendMail">
              <el-button plain>{{ $t("m.liji35") }}</el-button>
            </a>
          </div>
        </div>
        <div class="wow slideInUp">
          <img src="../assets/product/shou.png" />
        </div>
      </div>
    </div>
    <div class="allBox anchor">
      <div class="title1 name wow slideInUp">{{ $t("m.zhifu36") }}</div>
      <div class="title1 val">QR CODE PAYMENT</div>
      <div class="matter wow slideInUp">
        <div class="wow slideInUp">
          <img
            style="margin-left: 0; margin-right: 50px"
            src="../assets/product/abb.png"
          />
        </div>
        <div class="column">
          <div class="matterName">{{ $t("m.sao37") }}</div>
          <div class="matterVal">
            {{ $t("m.xinxia38") }}
          </div>
          <div class="btnBox">
            <a :href="'mailto:' + url" id="sendMail">
              <el-button plain>{{ $t("m.liji35") }}</el-button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <join></join>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import HelloWorld from "@/components/home/HelloWorld";
import productTop from "@/components/product/top";
import join from "@/components/home/join";
import * as API from "@/api/pageRequest/home"; //引入API
export default {
  name: "Home",
  components: {
    productTop: productTop,
    HelloWorld: HelloWorld,
    join: join,
  },
  data() {
    return {
      activeBanner: 1,
      activeView: 0,
      url: this.apiUrl,

      backList: "",
    };
  },
  methods: {
    idActive(num) {
      this.activeBanner = num;
    },
    StopRoll(num) {
      this.activeView = num;
    },
    UpRoll() {
      this.activeView = 0;
    },
    goRegist() {
      this.$router.push("/register");
    },
    jump(index) {
      // this.$router.push('/about')
      // this.$router.push('/product',query: {key: value}})
      // var index=index
      // let jump = document.querySelectorAll('.anchor')
      // // console.log("1")
      // if (jump[index].offsetTop) {
      // 	// 获取需要滚动的距离
      // 	let total = jump[index].offsetTop - 250
      // 	// Chrome
      // 	document.body.scrollTop = 1200
      // 	// Firefox
      // 	document.documentElement.scrollTop = total
      // 	// Safari
      // 	window.pageYOffset = total
      // 	// this.show = false
      // }
    },
    // 获取支付商类别
    getType() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
		data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("page", "1");
      data.append("pagesize", "200");
      API.getProduct(data)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res);
            that.backList = res.data.data.list;
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.jump, true);
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        live: false,
        // boxClass: ‘wow‘,
        // animateClass: ‘animated‘,
        offset: 0,
        mobile: true,
        live: true,
      });
      wow.init();
    });
    // console.log(this.$route.query.jumpIndex)
    var index = this.$route.query.jumpIndex;
    // this.$router.push({path:'about', query: {jumpIndex: index}})
    this.jump(index);
    this.getType();
  },
};
</script>

<style lang="less" scoped="scoped">
.product {
  div {
    word-wrap: break-word;
  }

  .activeView {
    // padding-top: 0px !important;
    transform: translate(10px, -20px);

    div {
      color: #1f2a68 !important;
    }
  }

  .matter {
    margin-top: 75px;
    display: flex;

    .column {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    img {
      width: 550px;
      height: 250px;
      // background-color: red;
      margin-left: 50px;
    }

    .matterName {
      font-size: 20px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #1f2a68;
      line-height: 28px;
    }

    .matterVal {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
      width: 500px;
    }
  }

  .details {
    padding-top: 50px;
    background: #f8f9ff;
    padding-bottom: 100px;

    .name {
      margin-top: 0;
    }

    .body {
      width: 1099px;
      // height: 112px;
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 100px;
    }

    .banner {
      display: flex;
      width: 1100px;
      margin: 0 auto;

      .bannerLeft div {
        transition: all 0.6s;
      }

      .bannerLeft div:hover {
        cursor: pointer;

        span {
          opacity: 0.3;
          text-decoration: underline;
        }
      }

      .bannerLeft {
        width: 360px;
        border-radius: 20px 0 0 20px;
        background: #f2f4ff;
        overflow: hidden;
        box-sizing: border-box;

        div {
          box-sizing: border-box;
          padding: 45px 33px;
          display: flex;
          align-items: center;
          font-size: 20px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          line-height: 28px;
          height: 120px;
          overflow: hidden;

          img {
            margin-right: 30px;
            width: 30px;
            height: 30px;
          }
        }

        .active {
          background: #1f2a68;

          color: #ffffff;
        }
      }

      .bannerRight {
        box-sizing: border-box;
        flex: 1;
        background-color: #fff;
        position: relative;
        padding: 30px 20px 50px 20px;

        .imgBox {
          position: absolute;
          // z-index: -1;
          top: 60px;
          width: 700px;
          height: 340px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .titleBox {
          font-size: 20px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #1f2a68;
          line-height: 28px;
          display: flex;
          flex-direction: column;
          height: 100%;

          .top {
            flex: 1;
            display: flex;
            align-items: flex-end;
          }
        }

        .bannerBox {
          display: flex;

          span {
            width: 368px;
            font-size: 16px;
            font-family: Helvetica;
            color: #1f2a68;
            line-height: 28px;
            display: block;
            word-wrap: break-word;
            margin-right: 50px;
          }
        }
      }
    }
  }

  .title1 {
    text-align: center;
  }

  .name {
    margin-top: 150px;
    font-size: 35px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #1f2a68;
    line-height: 42px;
  }

  .val {
    margin-top: 0px;
    font-size: 20px;
    font-family: Arial-Black, Arial;
    font-weight: 900;
    color: #e4e9ed;
    line-height: 28px;
    letter-spacing: 2px;
  }

  .zqPrduct {
    display: flex;
    padding-bottom: 100px;
    margin-top: 80px;

    .imgBox {
      width: 414px;
      height: 354px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .essay {
      padding-left: 50px;

      .headline {
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
        line-height: 28px;
        padding: 30px 0 10px 0;
      }

      .content {
        font-size: 16px;
        font-family: Helvetica;
        color: #1f2a68;
        line-height: 28px;
        width: 605px;
      }
    }

    .listBox {
      display: flex;

      .view {
        transition: all 0.6s;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;
        width: 170px;
        height: 280px;
        padding: 15px;
        box-sizing: border-box;
        margin-top: 22px;
        cursor: pointer;

        img {
          width: 75px;
          height: 120px;
        }

        div {
          margin-top: 12px;
          text-align: center;
          font-size: 16px;
          font-family: Helvetica;
          color: #1f2a68;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
