<template>
	<div class="indexTop allBox anchor" id="">
		<div class="bgBoxMble">
			<!-- <img v-if="$route.meta.bg==1" src="../../assets/index/bg.png" /> -->
			<img  src="../../assets/product/bg.png" />
		</div>
		<div class="bgBox  ">
			<img src="../../assets/product/bg.png" />
		</div>
		<div class="topTitle">{{$t('m.zhifuSDK2')}}</div>
		<div class="subhead">
			{{$t('m.fansuo3')}}
		</div>
		<div class="btnBox">
			<a :href="'mailto:'+url" id="sendMail"><el-button plain @click="email">{{$t('m.ljjieru4')}}</el-button></a>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	export default {
		name: "productTop",
		props: {
			msg: String,
		},
		data() {
			return {
				imgList: [

				],
				url: this.apiUrl
			};
		},
		mounted() {
			this.$nextTick(() => {
				// 在dom渲染完后,再执行动画
				const wow = new WOW({
					live: false,
					// boxClass: ‘wow‘,
					// animateClass: ‘animated‘,
					offset: 200,
					mobile: true,
					live: true
				})
				wow.init()
			})
		},
		methods:{
			email(){
				// alert("1")
			}
		}
	};
</script>

<style lang="less" scoped="scoped">
	.indexTop {
		.bgBoxMble{
			display: none;
			width: 100%;
			height: auto;
			img{
				width: 100%;
				height: auto;
				position: absolute;
				top: 0;
				z-index: -1;
			}
		}
		.btnBox {
			margin-top: 25px;
			.el-button {
				border-radius: 10px;
				padding: 10px 72px;
				// border: 1px solid #7180A1;
			}
		}

		// position: relative;
		.bgBox {
			position: absolute;
			z-index: -999;
			top: 0;
			height: 580px;
			width: 1200px;
			margin: 0 auto;

			img {
				width: 880px;
				width: 100%;
				height: 100%;
			}
		}

		.topTitle {
			width: 506px;
			// height: 105px;
			font-size: 30px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			line-height: 35px;
			margin-top: 152px;
		}

		.subhead {
			width: 506px;
			// height: 112px;
			font-size: 16px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 28px;
			margin-top: 26px;
		}

		.mian {
			display: flex;
			justify-content: space-around;
			margin-top: 180px;

			.box {
				width: 240px;
				height: 375px;
				background: #F8F9FF;
				border-radius: 20px;
			}

			.jewel {
				display: flex;

				.left {
					display: flex;
					flex-direction: column;
					flex: 1;
					height: 97px;

					.a {
						flex: 1;
					}

					.b {
						height: 28px;
						font-size: 20px;
						font-family: Helvetica-Bold, Helvetica;
						font-weight: bold;
						color: #1f2a68;
						line-height: 28px;
						// align-items: center;
						padding: 0 15px;
					}
				}



				div {}

				img {
					width: 97px;
					height: 149px;

				}
			}

			.footerTitle {
				margin-top: 10px;
				font-size: 16px;
				font-family: Helvetica;
				color: #1f2a68;
				line-height: 28px;
				padding: 15px;
			}
		}
	}
</style>
