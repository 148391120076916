<template>
	<!-- 转账页面 -->
	<div style="min-height: 100vh;" id="Transfer">
		<div class="top">
			<!-- <HelloWorld :myName="3"></HelloWorld> -->
		</div>
		<!-- 银行信息 -->
		<div class="Transfer " style="min-height: 100vh;">
			<div class="maxWidth title paadingBox ">
				<img src="../assets/index/1.png" />
				<span>RGPay</span>
				<div class="titleNav">{{$t('m.transfer.transfer')}}</div>
			</div>
			<div v-if="consequence" class="bankMessage paadingBox " style="margin-bottom: 30px;margin-top: 10px;">
				<img :src="bank_logo" style="margin-right: 10px;" />
				<span v-if="bankname!=''&&bankname"> ( {{bankname}} )</span>
			</div>
			<!-- 选项 -->
			<!-- consequence如果有结果 则隐藏table -->
			<div class="option" v-if="consequence">
				<div class="change paadingBox"
					v-if="allData.channel_info.collection_qr_code_img!=''&&allData.channel_info.collection_qr_code_img">
					<!-- <div class="change paadingBox"> -->
					<div v-for="(item,index) in changeInput" v-bind:class="{ isActive:index==current}" class=""
						changeInput @click="changeOption(index)">{{item}}</div>
				</div>
				<!-- 输入框选项 -->
				<div class="one paadingBox" v-if="optionVal=='one'">
					<div class="allBoxInput">
						<div class="gaine" v-for="(item,index) in indent" v-if="item.val">
							<div class="neirong">
								<div>{{item.title}}</div>
								<div>{{item.val}}</div>
							</div>
							<div class="Copy tag-read" v-bind:class="index" v-show="item.canCopt"
								:data-clipboard-text="item.val" @click="copy">{{$t('m.transfer.fuzhi530')}}</div>
							<div v-show="!item.canCopt" class="CopyNOT"></div>
						</div>
					</div>
				</div>
				<!-- 二维码选项 -->
				<div class="two" v-if="optionVal=='two'">
					<div class="money">{{allData.amount}} {{allData.currency}} </div>
					<img class="qrcode" :src="allData.channel_info.collection_qr_code_img" />
					<div class="Required paadingBox">
						<span>{{$t('m.transfer.bixu522')}}:</span>
						<span>{{allData.order_id}}</span>
						<span class="tag-read" :data-clipboard-text="allData.order_id"
							@click="copy">{{$t('m.transfer.fuzhi530')}}</span>
					</div>
					<div class="paadingBox">
						<div class="bulb ">
							<img src="../assets/Transfer/light.png" />
							<div>{{$t('m.transfer.shiyong523')}}</div>
						</div>
					</div>
					<!-- 4步骤 -->
					<div style="background-color: #F8F9FF;">
						<div class="step" style="max-width: 750px; margin: 0 auto;padding-bottom: 20px;">
							<div>
								<img src="../assets/Transfer/b1.png" />
								<span>{{$t('m.transfer.chongfu524')}}</span>
							</div>
							<div>
								<img src="../assets/Transfer/b2.png" />
								<span>{{$t('m.transfer.wangyin525')}}</span>
							</div>
							<div> <img src="../assets/Transfer/b3.png" />
								<span>{{$t('m.transfer.erweima526')}}</span>
							</div>
							<div> <img src="../assets/Transfer/b4.png" />
								<span>{{$t('m.transfer.wancheng527')}}</span>
							</div>
						</div>
					</div>
				</div>
				<!-- 公共按钮。提示 -->
				<div class="common" v-show="isTimeNoOut">
					<div class="paadingBox">
						<div class="Buttons">
							<el-button @click="checkBtn" :loading="isloading"
								style="width: 100%;height: 100%;background-color: #1f2a68;" type="primary">
								{{$t('m.transfer.wanchang513')}}
							</el-button>
							<!-- 	<i class="el-icon-loading" v-show="isloading"></i>
							<span style="margin-left: 20px;">{{$t('m.transfer.wanchang513')}}</span> -->
						</div>
						<div class="timeChange">
							<img src="../assets/pay/time.png" /><span>{{$t('m.transfer.shnegyu514')}}
							</span><span>
								<van-count-down @finish="finish" :time="time" format="ss" style="color: red;" />
							</span><span>{{$t('m.transfer.miao521')}}</span>
						</div>
						<div class="reminder">{{$t('m.transfer.fuwu515')}}</div>
					</div>
				</div>

				<!-- one-底部提示 -->
				<div class="paadingBox " v-if="optionVal=='one'" style="background-color:#F8F9FF;">
					<div class="oneFooter ">
						<img src="../assets/index/logo.png" />
						<div class="article">
							<div>[ {{$t('m.transfer.zhuanyi516')}} ] </div>
							</br>
							<div>{{$t('m.transfer.fangwen517')}}
								</br>
								</br>
								{{$t('m.transfer.youxiao518')}}({{allData.order_id}})
								{{$t('m.transfer.jiade5181')}} {{allData.timeout}}
								{{$t('m.transfer.jiade5182')}}
								</br>
								</br>
								{{$t('m.transfer.dengxin519')}}
								</br>
								</br>
								{{$t('m.transfer.quxiao520')}}{{allData.amount}} ({{allData.currency}})
								{{$t('m.transfer.quxiao5201')}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- *********************** -->
			<div class="timeOut" v-show="!isTimeNoOut">
				<!-- 超时 -->
				<div class="timeOutC" v-if="payStatus=='overtime'">
					<img src="../assets/pay/ms.png" />
					<div> {{$t('m.transfer.result')}}： {{$t('m.transfer.chaoshi531')}}</div>
				</div>
				<!-- 成功 -->
				<div class="timeOutC" v-if="payStatus=='success'">
					<img src="../assets/pay/success.png" />
					<div> {{$t('m.transfer.result')}}： {{$t('m.transfer.chenggong534')}}</div>
				</div>
				<!-- 失败 -->
				<div class="timeOutC" v-if="payStatus=='failure'">
					<img src="../assets/pay/error.png" />
					<div> {{$t('m.transfer.result')}}： {{$t('m.transfer.shibai532')}}</div>
				</div>
			</div>
			<div style="flex: 1; background: #F8F9FF;" v-if="consequence"></div>
		</div>

	</div>
</template>

<script>
import HelloWorld from "@/components/home/HelloWorld";
import Clipboard from "clipboard";
import * as API from "@/api/pageRequest/home"; //引入API
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      optionVal: "one",
      // changeInput: [this.$t("m.transfer.banking"), this.$t("m.transfer.payment507")],
      changeInput: ["", ""],
      current: 0,
      // --------------
      order_id: "",
      sign: "",
      // 银行logo
      bank_logo: "",
      // 银行名称
      bankname: "",
      cardnumber: "",
      allData: {
        channel_info: {
          collection_qr_code_img: "",
        },
      },
      time: 30 * 60 * 60 * 1000,
      isTimeNoOut: true,
      // 支付状态
      payStatus: "",
      // 定时器
      timing: "",
      isloading: false,
      url: "",
      consequence: true,
    };
  },
  computed: {
    indent() {
      return [
        {
          // 银行卡,
          title: this.$t("m.transfer.zhanghao508"),
          val: "",
          canCopt: true,
        },
        {
          // 卡主姓名
          title: this.$t("m.transfer.mingzi509"),
          val: "",
          canCopt: true,
        },
        {
          // 银行分行
          title: this.$t("m.transfer.fenhang510"),
          val: "",
          canCopt: false,
        },
        {
          // 总额
          title: this.$t("m.transfer.jine511"),
          val: "",
          canCopt: true,
        },
        {
          // 备注
          title: this.$t("m.transfer.neirong512"),
          val: "",
          canCopt: true,
        },
      ];
    },
  },
  mounted() {
    let that = this;
    if (that.$route.query.order_id) {
      that.order_id = that.$route.query.order_id;
    }
    if (that.$route.query.sign) {
      that.sign = that.$route.query.sign;
    }
    if (that.$route.query.lang) {
      that.$i18n.locale = that.$route.query.lang;
    }
    that.getOrder();
    that.changeInput[0] = this.$t("m.transfer.banking");
    that.changeInput[1] = this.$t("m.transfer.payment507");
  },
  methods: {
    finish(val) {
      var that = this;
      let valData = val;
      // Toast('倒计时结束');
      that.consequence = false;
      // return
      that.isTimeNoOut = false;
      // 超时未支付overtime
      // 支付成功success
      // 支付失败failure
      that.payStatus = "overtime";
      if (valData != "" && valData) {
        that.payStatus = valData;
      }
      if (that.url != "") {
        window.location.href = that.url;
      }
    },
    // 提交信息轮询校验接口
    checkBtn() {
      var that = this;
      that.isloading = true;
      that.submitPay();
      // that.timing = setInterval(function() {
      // 	that.submitPay()
      // 	console.log("1")
      // }, 3000)
    },
    // .统一银行OTP校验
    submitPay() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = that.$i18n.locale;
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("order_id", that.order_id);
      data.append("sign", that.sign);
      // data.append("otp", that.otp);
      API.postOrderQuery(data)
        .then((res) => {
          if (res.data.code == "1") {
            // 请求失败
            setTimeout(function () {
              that.submitPay();
            }, 3000);
          } else if (res.data.code == "0") {
            // 请求成功
            if (res.data.data.returncode == "00") {
              // 00表示成功
              // 支付成功
              that.payStatus = "success";
              that.finish("success");
              that.isloading = false;
              if (res.data.data && res.data.data.callback_url) {
                setTimeout(function () {
                  window.location.href = res.data.data.callback_url;
                }, 2000);
              }
            } else if (res.data.data.returncode == "11") {
              // 11 表示失败
              // 请求失败
              setTimeout(function () {
                that.submitPay();
              }, 3000);
              // 支付失败
              // that.payStatus = "failure"
              // that.finish("failure")
            }
          } else if (res.data.code == "2") {
            // 超时未支付
            that.payStatus = "overtime";
            that.finish("overtime");
            that.isloading = false;
          }
          if (res.data.data && res.data.data.callback_url) {
            that.url = res.data.data.callback_url;
          }
          // clearInterval(that.timing)
        })
        .catch((err) => {
          that.state1 = 2;
          // clearInterval(that.timing)
        });
    },
    // 1.获取统一银行支付订单信息
    getOrder() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = that.$i18n.locale;
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("order_id", that.order_id);
      data.append("sign", that.sign);
      API.getOrderData(data)
        .then((res) => {
          if (res.data.code == 1) {
            // ==1表示订单不可支付状态-显示状态为支付超时
            // 超时未支付
            that.payStatus = "overtime";
            that.finish("overtime");
          } else {
            that.bank_logo = res.data.data.channel_info.bank_logo;
            that.bankname = res.data.data.channel_info.bankname;
            var arr = Object.keys(res.data.data.channel_info);
            that.indent[3].val = res.data.data.amount;
            that.indent[4].val = res.data.data.order_id;
            for (var i in arr) {
              if (arr[i] == "cardnumber") {
                that.indent[0].val = res.data.data.channel_info.cardnumber;
              }
              if (arr[i] == "full_name") {
                that.indent[1].val = res.data.data.channel_info.full_name;
              }
              if (arr[i] == "branch_name") {
                that.indent[2].val = res.data.data.channel_info.branch_name;
              }
            }
            that.allData = res.data.data;
            that.time = res.data.data.timeout * 1000;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeOption(index) {
      console.log(index);
      if (index == 0) {
        this.optionVal = "one";
      } else {
        this.optionVal = "two";
      }
      this.current = index;
    },
    // 复制
    copy() {
      var that = this;
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // console.log('复制成功')

        this.$message({
          message: that.$t("m.transfer.fail528"),
          type: "success",
          offset: "180",
          center: true,
          duration: "1000",
        });
        //  释放内存
        // clipboard.destory()
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.$message({
          message: that.$t("m.transfer.succsee529"),
          type: "error",
          offset: "180",
          center: true,
          duration: "1000",
        });
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.Buttons:hover {
  opacity: 0.8;
  cursor: pointer;
}

// 超时
.timeOut {
  // background-color: rgb(248, 249, 255);
  padding-top: 20px;

  .timeOutC {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px 50px 15px;
    text-align: center;

    img {
      height: 104px;
    }

    div {
      font-size: 16px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #1f2a68;
      padding-top: 10px;
    }
  }
}

#app {
  height: 100vh;
}

div {
  box-sizing: border-box;
}

.maxWidth {
  max-width: 1200px;
  margin: 0 auto;
}

.Transfer {
  display: flex;
  flex-direction: column;

  .one {
    margin-bottom: 50px;
  }

  // 内边距
  .paadingBox {
    padding: 0 15px;
  }

  .two {
    .money {
      font-size: 20px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ff0097;
      text-align: center;
      padding: 15px 0;
    }

    .qrcode {
      width: 150px;
      margin: 0 auto;
      display: block;
    }

    .Required {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 23px;

      span:nth-child(1) {
        font-size: 12px;
        font-family: Helvetica;
        color: #1f2a68;
      }

      span:nth-child(2) {
        font-size: 14px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
        padding: 0 15px 0 5px;
      }

      span:nth-child(3) {
        display: inline-block;
        min-width: 60px;
        height: 30px;
        background: #4a5d87;
        border-radius: 5px;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Helvetica;
        color: #ffffff;
      }
    }

    .bulb {
      margin: 0 auto;
      border-radius: 23px;
      border: 1px solid #ff006a;
      display: flex;
      align-items: center;
      padding: 9px 15px;
      max-width: 456px;
      margin-top: 39px;
      margin-bottom: 25px;

      img {
        height: 21px;
        margin-right: 12px;
      }

      div {
        font-size: 12px;
        font-family: Helvetica;
        color: #ff006a;
      }
    }

    .step {
      background: #f8f9ff;
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      padding-top: 15px;

      div {
        padding: 10px;
        background-color: #fff;
        // height: 50px;
        // flex: 1;
        width: 75px;
        min-height: 110px;
        border-radius: 10px;
        font-size: 10px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #6e7ea7;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        img {
          width: 30px;
          margin-bottom: 12px;
        }
      }

      span {
        text-align: center;
      }
    }
  }

  //
  margin: 0 auto;

  .bankMessage {
    position: relative;
    right: 0;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 129px;
    }

    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
    }
  }

  .title {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;

    img {
      width: 25px;
      margin-right: 8px;
    }

    span {
      font-size: 18px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #1f2a68;
    }

    .titleNav {
      display: none;
    }
  }

  .change {
    display: flex;
    text-align: center;
    justify-content: center;

    div {
      padding: 10px 39px;
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
    }

    .isActive {
      border-bottom: 2px solid #1f2a68;
    }
  }

  .allBoxInput {
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #c1c8d6;
    overflow: hidden;
    margin-top: 30px;
    border-bottom: none;
    max-width: 450px;

    .gaine {
      display: flex;

      .neirong {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #c1c8d6;
        height: 60px;

        div {
          // flex: 1;
          margin-top: 12px;
          display: flex;
          // align-items: center;
          padding-left: 20px;
        }

        div:nth-child(1) {
          font-size: 12px;
          font-family: Helvetica;
          color: #6e7ea7;
        }

        div:nth-child(2) {
          font-size: 12px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #1f2a68;
        }
      }

      .Copy {
        width: 60px;
        height: 60px;
        background: #4a5d87;
        // border-radius: 0px 10px 0px 0px;
        border-bottom: 1px solid #c1c8d6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: Helvetica;
        color: #ffffff;
      }

      .CopyNOT {
        width: 60px;
        height: 60px;
        border-bottom: 1px solid #c1c8d6;
      }
    }
  }

  .common {
    background: #f8f9ff;
    padding-top: 40px;

    .Buttons {
      margin: 0 auto;
      // min-width: 315px;
      max-width: 450px;
      background: #1f2a68;
      border-radius: 10px;
      font-size: 16px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ffffff;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      button {
        border-radius: 10px;
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .timeChange {
      margin-top: 15px;
      text-align: center;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;

      img {
        width: 12px;
        margin-right: 5px;
      }

      span {
        font-size: 16px;
        font-family: Helvetica;
        color: #1f2a68;
      }

      span:nth-child(3) {
        color: #ff006a;
        margin: 0 5px;
      }
    }

    .reminder {
      margin: 0 auto;
      margin-top: 21px;
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
      padding-bottom: 69px;
      max-width: 456px;
    }
  }

  .oneFooter {
    background: #f8f9ff;
    display: flex;
    padding: 0 0 69px 0;
    max-width: 1000px;
    margin: 0 auto;

    img {
      height: 89px;
    }

    div {
      margin-left: 25px;
    }

    div:nth-child(1) {
      font-size: 20px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #1f2a68;
    }

    div:nth-child(2) {
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
    }
  }
}

// 屏幕大于1000
@media screen and (min-width: 1000px) {
  .Transfer {
    .change {
      div {
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
      }

      .isActive {
        border-bottom: 4px solid #1f2a68;
      }
    }

    // 内边距
    .paadingBox {
      padding: 0 15px;
    }

    .allBoxInput {
      .gaine {
        .neirong {
          align-items: center;
          flex-direction: row;

          div {
            flex: 1;
            margin-top: 0;
          }

          div:nth-child(2) {
            font-size: 14px;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #1f2a68;
          }
        }
      }
    }

    .title {
      width: 1200px;

      img {
        width: 50px;
      }

      .titleNav {
        flex: 1;
        text-align: right;
        font-size: 20px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
        display: block !important;
      }
    }

    .bankMessage {
      justify-content: flex-end;
      position: relative;
      right: 0;
      margin: 0 auto;
      width: 1200px;

      span {
        font-size: 16px;
        font-family: Helvetica;
        color: #1f2a68;
      }
    }

    .two {
      .step {
        div {
          width: 150px;
          height: 120px;

          img {
            width: 60px;
          }
        }
      }
    }
  }

  .top {
    display: none;
  }
}
</style>
