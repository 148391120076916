<template>
  <div class="pay">
    <HelloWorld :myName="3"></HelloWorld>
    <div class="cellphonePay">
      <div class="rollBox">
        <img src="../../assets/index/banner/10.png" />
      </div>
      <div class="code">
        <div class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div class="money">
        <div style="margin-top: 20px">
          <span class="mum">4000</span
          ><span
            style="flex: 1; text-align: left; margin-left: 5px"
            class="title"
            >RM</span
          ><span class="btn" @click="saveQrcode">{{ $t("m.baocun124") }}</span>
        </div>
        <div class="title" style="margin-top: 15px">
          {{ $t("m.chognzhi125") }}
        </div>
        <div style="margin-top: 10px">
          <div class="dingdan">
            <span class="mum" style="flex: 1; text-align: left">{{
              demoVal.pay_orderid
            }}</span>
          </div>
          <span
            class="btn copy-btn"
            v-clipboard:copy="demoVal.pay_orderid"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t("m.copy126") }}</span
          >
        </div>
      </div>
      <div class="mobileBtn">z和ifu</div>
      <div class="ishi">
        <div>1.{{ $t("m.one128") }}</div>
        <div style="margin-bottom: 50px">2.{{ $t("m.qr129") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
import payType1 from "@/components/module/payType1";
import { Loading } from "element-ui";
export default {
  name: "",
  props: {
    myName: Number,
  },
  components: {
    HelloWorld: HelloWorld,
    payType1: payType1,
  },
  data() {
    return {
      payNumber: "0.00",
      payType: "",
      current: 0,
      demoVal: {
        pay_amount: 0,
      },
      demoId: "",
      current0: 0,
      keyNameBank: "",
      dataForm: "",
      pay_orderid: "",
      requestTime: "",
      current01: 1,
      payTypeNameVal: 1,
      bankName: 0,
      // main show
      isShowMain: false,
    };
  },
  mounted() {
    this.getType();
    this.getOrderVal();
    this.creatQrCode();
    this.geUelData();
    // console.log(this.geUelData())
    if (this.geUelData()) {
      this.postUrlData();
    }
    // this.geUelData()
  },
  methods: {
    // 获取url参数
    geUelData() {
      var url = window.location.href; //获取当前url
      // console.log(url)
      // if(!url){

      // }
      var dz_url = url.split("#")[0]; //获取#/之前的字符串
      var cs = dz_url.split("?")[1]; //获取?之后的参数字符串
      var cs_arr = cs.split("&"); //参数字符串分割为数组
      var cs = {};
      var that = this;
      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象
        cs[cs_arr[i].split("=")[0]] = cs_arr[i].split("=")[1];
      }
      this.pay_orderid = cs.cartId;
      // 后台时间戳  秒
      this.requestTime = cs.requestTime;
      // 当前时间
      const timestamp = Date.parse(new Date());
      // 当前时间  秒
      const timeData = (timestamp * 1) / 1000;
      console.log(timeData, that.requestTime);
      // 一天
      const oneData = 24 * 60 * 60;
      // const timeReally=this.requestTime*1+oneData
      // 相差
      // var time = timeData - timeReally
      if (timeData * 1 - that.requestTime * 1 >= oneData) {
        // 大于1天
        that.$router.push("/pay");
        console.log("p");
        // return false
      } else {
        // 小于一天
        console.log("p1");
        return true;
      }
      // this.pay_md5sign = cs.cartId;
    },
    // 传输urldata
    postUrlData() {
      // postUrlData
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
		data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("pay_orderid", that.pay_orderid);
      API.postUrlData(data)
        .then((res) => {
          if (res.data.code == 0) {
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goHome() {
      this.$router.push("/");
    },
    // 获取支付商类别
    getType() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else {
        // console.log(lang)
        data.append("lang", "en-Us");
      }
      data.append("page", "1");
      data.append("pagesize", "100");
      API.getProduct(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.payType = res.data.data;
            // console.log(res.data.data);
            // this.$message.success('注册成功');
            that.demoId = res.data.data.list[0].id;
            // alert(res.data.data.list[0].id)
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addClass0(index, item) {
      this.current0 = index;
      // console.log(item)
      this.keyNameBank = item;
    },
    addClass01(index, item) {
      this.current01 = index;
      // console.log(item)
      this.payTypeNameVal = index;
    },
    addClass: function (index, event, id) {
      // console.log(id)
      this.demoId = id;
      this.current = index;
      //获取点击对象
      var el = event.currentTarget;
      this.bankName = index;
    },
    // 获取订单信息
    getOrderVal(index) {
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
		data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }

      API.getDemo(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.demoVal = res.data.data;
            if (index) {
              this.keyNameBank = res.data.data.bankcode[index];
            } else {
              this.keyNameBank = res.data.data.bankcode[0];
            }
            // this.keyNameBank = res.data.data.bankcode[0];
            // console.log(res.data.data);
            // this.$message.success('注册成功');
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 发起支付postDemoOrderPay
    goPay() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else {
        // console.log(lang)
        data.append("lang", "en-Us");
      }
      data.append("pay_orderid", that.demoVal.pay_orderid);
      data.append("amount", that.demoVal.pay_amount);
      data.append("pay_bankcode", that.demoId);
      data.append("bankcode", that.keyNameBank);

      data.append("pay_type", that.payTypeNameVal);
      API.postDemoOrderPay(data)
        .then((res) => {
          that.dataForm = res.data;
          // console.log(res.data.data.url)
          // 跳转url
          if (res.data.data.url) {
            // setTimeout(function(){
            window.open(res.data.data.url, "payWindow");
            // },200)
          }
          setTimeout(function () {
            that.getOrderVal(that.current0);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: "www.rg-pay.com",
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 保存二维码
    saveQrcode() {
      //找到canvas标签
      const myCanvas = document
        .getElementById("qrcode")
        .getElementsByTagName("canvas");
      //创建一个a标签节点
      const a = document.createElement("a");
      //设置a标签的href属性（将canvas变成png图片）
      a.href = myCanvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      //设置下载文件的名字
      a.download = "qrcode.png";
      //点击
      a.click();
      // Toast.success('保存成功');
      this.$toast.success("");
    },
    // 复制
    // 复制成功时的回调函数
    onCopy(e) {
      this.$toast.success("");
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$toast.fail("");
    },
  },
};
</script>

<style lang="less" scoped="scoped">
@media screen and (min-width: 1000px) {
  body {
    div {
      text-align: center;
    }

    .money {
      text-align: center;
      .mum {
        font-size: 18px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #ff006a;
      }
      .title {
        font-size: 12px;
        font-family: Helvetica;
        color: #1f2a68;
      }
      .btn {
        // width: 140px;
        // height: 35px;
        background: #4a5d87;
        border-radius: 5px;
        display: inline-block;
        padding: 8px 20px;
        text-align: center;
        font-size: 12px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #ffffff;
        margin-left: 20px;
      }

      .mum {
        text-align: center !important;
        margin-bottom: 20px;
      }
    }

    .ishi {
      div {
        margin-top: 20px;
        font-size: 12px;
        font-family: Helvetica;
        color: #cd0f5e;

        line-height: 14px;
      }
    }

    .code {
      width: 200px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 5px 15px 0px rgba(55, 65, 93, 0.15);
      border-radius: 15px;
      margin: 0 auto;
    }
  }
}

.pay {
  .dingdan {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;
    text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
  }

  .mobileBtn {
    width: 19.6875rem;
    height: 2.8125rem;
    background: #1f2a68;
    border-radius: 10px;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #ffffff;
    line-height: 28px;
    margin: 0 auto;
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qrcode {
    width: 200px;
    height: 200px;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .cellphonePay {
    // display: none;
    max-width: 1200px;
    margin: 0 auto;
  }

  .rollBox {
    height: 160px;
    white-space: nowrap;
    overflow: scroll;
    padding: 0 20px;
    display: flex;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;

    img {
      border: 2px solid #fff;
      max-width: 200px;
    }
  }

  .rollBox::-webkit-scrollbar {
    display: none;
  }

  .blue {
    border: 2px solid #1f2a68 !important;
  }

  .bottom {
    text-align: center;

    font-size: 14px;
    font-family: Helvetica;
    color: #6e7ea7;
    line-height: 28px;
  }

  .payment {
    width: 1100px;
    // height: 120px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 auto;
    margin-top: 30px;

    font-size: 16px;
    font-family: Helvetica;
    color: #1f2a68;
    line-height: 28px;
    padding: 15px 0;

    .btn {
      width: 180px;
      height: 60px;
      background: #1f2a68;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 30px;
      font-size: 16px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #ffffff;
      line-height: 28px;
      cursor: pointer;
    }
  }

  .way {
    width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 34px 30px 60px 30px;
    margin-top: 30px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Helvetica;
    color: #1f2a68;
    line-height: 28px;

    .option {
      display: flex;
      justify-content: space-around;
      margin-top: 48px;
      flex-wrap: wrap;

      img {
        width: 80%;
        height: auto;
        // border-radius: 10px;
        // border: 2px solid #fff;
      }

      .imgValBox {
        width: 150px;
        height: 80px;
        border: 2px solid #e5e5e5;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 88888;
        margin-bottom: 30px;
        overflow: hidden;
      }

      .imgValBox:hover {
        border: 2px solid #1f2a68;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }

  div {
    box-sizing: border-box;
  }

  .payBox {
    width: 1100px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;

    border-radius: 10px;

    .name,
    .jine {
      display: flex;
    }

    .jine {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        margin: 0 10px;
      }
    }

    .name {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }

    .number {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }
  }

  .top {
    background-color: #fff;
    height: 120px;
    display: flex;
    align-items: center;

    .topLine {
      width: 1100px;
      display: flex;
      align-items: center;
      margin: 0 auto;

      div {
        flex: 1;
        text-align: right;
      }

      img {
        width: 45px;
        height: 49px;
        cursor: pointer;
      }
    }
  }

  .main {
    background: #f8f9ff;
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .el-input__inner {
    height: 35px;
  }

  .backListBox {
    .bank {
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }

    .backList {
      // text-align: center;
      span {
        display: inline-block;
        padding: 5px 20px;
        border: 1px solid #e5e5e5;
        margin-right: 10px;
        border-radius: 5px;
        margin-top: 10px;
      }

      span:hover {
        cursor: pointer;
        border: 1px solid #1f2a68;
      }
    }
  }
}
</style>
