<template>
	<!-- 验证码输入页 -->
	<div class="captchaInputPage">
		<HelloWorld :myName="3"></HelloWorld>
		<div class="capt">
			<div class="payBankLogo flexBox">
				<img src="../../assets/index/banner/10.png" />
			</div>
			<div class="title">Số tiền :</div>
			<div class="valNum">RM 40,000</div>
			<div class="title">Thời gian:</div>
			<div class="val">2021-05-17 09:04:12</div>
			<div class="title">Mã giao dịch:</div>
			<div class="val">LANCID60A1D7ED49607LANCID60A1D7ED49607LANCID60A1D7ED49607</div>
			<div class="timeOut">
				<span class="val">Thời gian còn lại:</span>
				<span class="valNum">554</span>
				<span class="val">giây</span>
			</div>
			<div class="title">
				trong tin nhắn hoặc ứng dụng ngân hàng của bạn với RefID là: 1654654
				trong tin nhắn hoặc ứng dụng ngân hàng của bạn với RefID là:
			</div>
			<div class="inoputBox">
				<el-input v-model="input" placeholder="请输入内容"></el-input>
			</div>
			<div class="tishi">Quý khách lưu ý thời gian thực hiện OTP chỉ có 60 giây</div>
			<div class="btn flexBox">
				主要按钮
			</div>
			<div  class="tishi">
				[ Lưu ý] Mỗi mã QR hoặc thông tin gửi tiền chỉ được sử dụng 1 lần, nếu sử dụng lần nữa có khả năng thất thoát giao dịch, cần thời gian 1 tuần để xử lý. Quý khách vui lòng thực hiện theo hướng dẫn nạp tiền để tránh xảy ra sai xót. Xin cảm ơn
			</div>
		</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "payType1",
		props: {
			msg: String,
		},
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {
				input: ""
			}
		},
	}
</script>

<style lang="less" scoped="scoped">
	div {
		white-space: normal;
		word-break: break-all;
		width: 100%;
	}
	@media screen and (min-width:1000px) {
	    div{
			text-align: center;
		}
	}
	.capt {
		width: 100%;
		max-width: 1200px;
		// background-color: #1f2a68;
		padding: 20px;
		margin: 0 auto;

		.payBankLogo {

			img {
				width: 200px;
				height: auto;
			}

		}

		.title {
			font-size: 12px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 14px;
		}

		.valNum {
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #FF006A;
			line-height: 19px;
		}

		.val {
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			line-height: 19px;
		}

		.timeOut {
			// background-color: #F8F9FF;
			padding: 20px 0;
			text-align: center;
			
		}

		.inoputBox {
			margin-top: 20px;
			max-width: 500px;
			margin: 0 auto;
			margin-top: 20px;
		}

		.tishi {
			font-size: 12px;
			font-family: Helvetica;
			color: #FF006A;
			line-height: 14px;
			margin: 0 auto;
			margin-top: 20px;
		}
		.btn{
			background: #1f2a68;
			border-radius: 10px;
			max-width: 500px;
			height: 45px;
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #FFFFFF;
			margin: 0 auto;
			margin-top: 20px;
			margin-bottom: 20px;
		}
		.btn:hover{
			opacity: 0.8;
			cursor: pointer;
		}
	}
</style>
