<template>
  <div class="aboutUs">
    <HelloWorld :myName="6"></HelloWorld>
    <div class="top">
      <div class="aboutName1">{{ $t("m.women102") }}</div>
      <div class="aboutName2">ABOUT US</div>
    </div>
    <div class="aboutName11">{{ $t("m.lianxi103") }}</div>
    <div class="aboutName22">CONTACT US</div>
    <div class="main">
      <div style="height: 75px"></div>
      <div class="allBox main1">
        <div class="list widthList">
          <!-- qq -->
          <!-- <div>{{$t('m.qiyeqq104')}}:{{aboutUs.data.qq}}</div> -->
          <!-- 客服qq -->
          <!-- <div>{{$t('m.kefuqq105')}}:yiichiiping@hotmail.com </div> -->
          <!-- 咨询热线 -->
          <!-- <div>{{$t('m.zixun106')}}{{aboutUs.data.tel}}</div> -->
          <!-- 邮箱 -->
          <div class="emailBox">
            {{ $t("m.lainxi107") }}&nbsp;&nbsp;&nbsp;&nbsp;
            <a style="color: #1f2a68" id="">
              <span>yiichiiping@hotmail.com</span>
            </a>
          </div>
          <div class="emailBox">
            Telegram:&nbsp;&nbsp;&nbsp;&nbsp;
            <a style="color: #1f2a68">
              <!-- :href="'mailto:'+url" id="" -->
              <span>
                <a style="color: rgb(13, 38, 95)" s
                  >A9admin_99</a
                >
              </span>
            </a>
          </div>
          <div class="emailBox">
            Skype:&nbsp;&nbsp;&nbsp;&nbsp;
            <a style="color: #1f2a68">
              <span>
                <a
                  style="color: #1f2a68"
                  >live:.cid.c15c361aa206fac7</a
                >
              </span>
            </a>
          </div>
          <!-- 地址 -->
          <!-- <div>{{$t('m.adress109')}}:{{aboutUs.data.address}}</div> -->
        </div>
        <div class="codeBox codeVal">
          <!-- <div class="qrcode" ref="qrCodeUrl"></div> -->
          <img
            src="../assets/index/skype.png"
            style="width: 100%; height: 100%; border-radius: 12px"
          />
        </div>
      </div>
    </div>
    <div class="aboutName11">{{ $t("m.hezuo110") }}</div>
    <div class="aboutName22">CHANEL COOPERATION</div>
    <div class="main collaborate">
      <div class="allBox main1">
        <div class="left wow slideInUp">
          <div class="list">
            <img src="../assets/aboutUs/kefu.png" />
          </div>
          <div class="matter">{{ $t("m.wenan112") }}</div>
          <a :href="'mailto:' + url" id="sendMail">
            <div class="btn">{{ $t("m.lianxi113") }}</div>
          </a>
        </div>
        <div class="right wow slideInUp">
          <div class="codeBox">
            <img src="../assets/aboutUs/shoushou.png" />
          </div>
          <div class="matter">{{ $t("m.fuwu116") }}</div>
          <a :href="'mailto:' + url" id="sendMail">
            <div class="btn">{{ $t("m.kefu117") }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
import QRCode from "qrcodejs2";
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      aboutUs: {
        data: {
          address: "",
          domain: "",
          email: "",
          qq: "",
          tel: "",
          websitename: "",
        },
      },
      url: this.apiUrl,
    };
  },
  mounted() {
    // this.creatQrCode();
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      const wow = new WOW({
        live: false,
        // boxClass: ‘wow‘,
        // animateClass: ‘animated‘,
        offset: 300,
        mobile: true,
        live: true,
      });
      wow.init();
    });
    this.getAboutUs();
  },
  methods: {
    getAboutUs() {
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      console.log(lang, "aboutUS");
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }

      API.getAboutUs(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.aboutUs = res.data;
            console.log(res.data);
            // this.$message.success('注册成功');
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // creatQrCode() {
    // 	var qrcode = new QRCode(this.$refs.qrCodeUrl, {
    // 		text: 'www.score99pay.com',
    // 		width: 250,
    // 		height: 250,
    // 		colorDark: '#000000',
    // 		colorLight: '#ffffff',
    // 		correctLevel: QRCode.CorrectLevel.H
    // 	})
    // },
  },
};
</script>

<style lang="less" scoped="scoped">
.emailBox {
  span {
    cursor: pointer;
    text-decoration: underline;
  }

  span:hover {
    opacity: 0.8;
    color: #1f2a68;
  }

  a:hover {
    opacity: 0.8;
    color: #1f2a68 !important;
  }
}

.widthList {
  width: 531px;
}

.codeVal {
  box-sizing: border-box;
  border: 25px solid #ebeeff;
}

.main {
  background: #f8f9ff;
  padding-bottom: 75px;

  .list {
    font-size: 18px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #1f2a68;
    line-height: 39px;
  }
}

.main1 {
  display: flex;
  justify-content: space-around;
}

.aboutUs {
  .btn {
    margin: 0 auto;
    width: 240px;
    height: 42px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #7180a1;
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #6e7ea7;
    line-height: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    cursor: pointer;
  }

  .btn:hover {
    border-color: #409eff;
    color: #409eff;
  }

  .collaborate {
    background-color: #fff;

    img {
      width: 100%;
      height: 100%;
    }

    padding-bottom: 150px;

    .main1 {
      .left,
      .right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .list,
      .codeBox {
        width: 240px;
        height: 180px;
        // background: #D8D8D8;
        background-color: #fff;
        // opacity: 0.4;
        border-radius: 0 !important;
      }
    }

    .matter {
      text-align: center;
      margin-top: 50px;
      width: 402px;
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 28px;
    }
  }

  .codeBox {
    width: 300px;
    height: 300px;
    background: #ebeeff;
    border-radius: 33px;
  }

  .aboutName11 {
    font-size: 35px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #1f2a68;
    line-height: 42px;
    text-align: center;
    padding-top: 80px;
    background: #fff;
  }

  .aboutName22 {
    text-align: center;
    padding-bottom: 70px;

    background: #fff;
    font-size: 20px;
    font-family: Arial-Black, Arial;
    font-weight: 900;
    color: #e4e9ed;
    line-height: 28px;
    letter-spacing: 2px;
  }

  .top {
    height: 200px;
    width: 100%;
    background-image: url(../assets/aboutUs/bg.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .aboutName1 {
      font-size: 35px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #ffffff;
      line-height: 42px;
    }

    .aboutName2 {
      margin-top: 9px;
      font-size: 20px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 2px;
    }
  }
}
</style>
