<template>
	<div class="LoginAndRegister">
		<HelloWorld :myName="4"></HelloWorld>
		<div class="loginBox ">
			<div class="form">
				<!-- <div class="regist">商户注册</div> -->
				<div class="regist" v-if="path=='tenant'">{{$t('m.shanghu71')}}</div>
				<div class="regist" v-else-if="path=='agency'">{{$t('m.daili74')}}</div>
				<div v-else class="regist">{{$t('m.shanghu71')}}</div>
				<div class="inputBox">
					<div class="borderBox" :class="{ active:1==isActive}">
						<input v-model="name" class="inputBox1" :placeholder="$t('m.shuru72')" @focus="myFunction(1)"
							@blur="onBlur(1)" />
					</div>
					<!-- <div class="borderBox1" :class="{ active:2==isActive}">
						<input class="inputBox1" placeholder="请输入账号" @focus="myFunction(2)" @blur="onBlur(1)" />
					</div>
					<div class="borderBox1" :class="{ active:3==isActive}">
						<input class="inputBox1" placeholder="请输入账号" @focus="myFunction(3)" @blur="onBlur(1)" />
					</div> -->
					<div class="borderBox1 borderBox2" :class="{ active:4==isActive}">
						<input v-model="password" class="inputBox1" :placeholder="$t('m.mima73')" @focus="myFunction(4)"
							@blur="onBlur(1)" type="password" />
					</div>
				</div>
				<div class="btn" @click="login">{{$t('m.denlu70')}}</div>
				<div class="tishi">{{$t('m.meiyou75')}}<span @click="goRegist">{{$t('m.zhuce76')}}</span></div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		WOW
	} from 'wowjs'
	import * as API from "@/api/pageRequest/home"; //引入API
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "Home",
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {
				isActive: 0,
				path: "",
				listNumber: "0",
				// 用户名
				name: "",
				// 密码
				password: "",
				// 登录类型 user:商户 agent:代理,必填
				type1: "user",
				type2: "agent",
				ip: ""
			}
		},
		created() {
			let that = this;
			// document.onkeydown = function(e) {
			// 	e = window.event || e;
			// 	// console.log(e.keyCode)
			// 	if(e.keyCode==13){
			// 		that.login()
			// 	}
			// }
		},
		mounted() {
			this.path = localStorage.getItem('userId');
			this.$nextTick(() => {
				// 在dom渲染完后,再执行动画
				const wow = new WOW({
					live: false,
					// boxClass: ‘wow‘,
					// animateClass: ‘animated‘,
					offset: 100,
					mobile: true,
					live: true
				})
				wow.init()
			})
			this.ready();
		},
		methods: {
			myFunction(val) {
				this.isActive = val
			},
			onBlur(val) {
				this.isActive = 0
			},
			ready() {
				// var cip = returnCitySN["cip"];
				// this.ip=cip
				// console.log(cip); //cip就是本机ip地址
			},
			// 登陆
			login() {
				// alert("1")
				var that = this
				if (this.name.trim() == "") {
					this.$message.error({
						message: that.$t('m.userName118'),
						offset: 180
					});
					return
				} else if (this.password.trim() == "") {
					this.$message.error({
						message: that.$t('m.password119'),
						offset: 180
					});
					return
				}
				let data = new URLSearchParams()
				// 获取当前语言状态
				let lang = localStorage.getItem("lang")
				// console.log(lang)
				if (lang == "vl-VN") {
					data.append('lang', 'vi')
				} else {
					console.log(lang)
					data.append('lang', 'en-Us')
				}
				data.append('username', this.name)
				data.append('password', this.password)
				// data.append('client_id', this.ip)
				const valPath = localStorage.getItem('userId');
				if (valPath == 'tenant') {
					data.append('login_type', this.type1)
				} else if (valPath == 'agency') {
					data.append('login_type', this.type2)
				} else {
					data.append('login_type', this.type1)
				}
					// alert("1")
				API.postLogin(data).then(res => {
					// alert("1")
					if (res.data.code == 0) {
						that.$message.success({
							message: res.data.msg,
							offset: 180
						});
						window.open(res.data.data.url)
					} else {
						// that.$message.success({
						// 	message: res.data.msg,
						// 	offset: 180
						// });
					}
				}).catch(err => {
					console.log(err.msg);
				})
			},
			// 注册
			goRegist() {
				this.$router.push('/register')
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.loginBox {
		background: #F8F9FF;
		padding: 50px 0 150px 0;

		.active {
			border-color: #1f2a68 !important;
			z-index: 999999;
			border-top: 1px solid #1f2a68 !important;
		}

		.form {
			width: 750px;
			// height: 620px;
			background: #FFFFFF;
			border-radius: 10px;
			margin: 0 auto;
			box-sizing: border-box;
			padding: 50px 125px 66px 125px;

			.inputBox {
				margin-bottom: 50px;
			}

			.tishi {
				font-size: 16px;
				font-family: Helvetica;
				color: #1f2a68;
				line-height: 28px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-top: 22px;

				span {
					font-size: 16px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #1f2a68;
					line-height: 28px;
					cursor: pointer;
					margin-left: 10px;
				}
			}

			.btn {
				width: 500px;
				height: 60px;
				background: #1f2a68;
				border-radius: 10px;
				font-size: 16px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 28px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.regist {
				font-size: 35px;
				font-family: Helvetica-Light, Helvetica;
				font-weight: 300;
				color: #1f2a68;
				line-height: 42px;
				text-align: center;
				margin-bottom: 62px;
			}

			.inputBox {
				// border: 1px solid #C1C8D6;
				box-sizing: border-box;
				border-radius: 10px;

				// overflow: hidden;
				.inputBox1 {
					border-radius: 10px 10px 0px 0px !important;
				}

				.borderBox {
					border: 1px solid #C1C8D6;
					border-radius: 10px 10px 0px 0px;
				}

				.borderBox1 {
					border-bottom: 1px solid #C1C8D6;
					border-left: 1px solid #C1C8D6;
					border-right: 1px solid #C1C8D6;
				}

				.borderBox2 {
					border-radius: 0px 0px 10px 10px;
					overflow: hidden;

					input {
						border-radius: 0px 0px 10px 10px;
					}
				}

				input {
					width: 100%;
					height: 60px;
					background: #FFFFFF;
					border: none;
					padding: 0 34px;
					box-sizing: border-box;
				}

				.inputBox1:focus {
					outline: none;
				}
			}

		}

	}
</style>
