<template>
	<div class="toPay">
		<HelloWorld :myName="3"></HelloWorld>
		<div class="mainBpx">
			<div class="title">{{$t('m.wait167')}}</div>
			<div class="money">{{$t('m.jine168')}}</div>
			<div class="num">VDN 444</div>
			<div class="bianhao">{{$t('m.bianhao169')}}</div>
			<div class="haoma">1616aaa</div>
			<div class="bianhao">{{$t('m.shijian170')}}</div>
			<div class="haoma">2021-05-17:14:44:13</div>
			<div class="bianhao footer">{{$t('m.zhuangtai171')}}</div>
			<div class="imgBox">
				<img src="../../assets/pay/loading.gif" />
			</div>
			<div class="bianhao footer">{{$t('m.shibai173')}}</div>
			<div class="imgBox">
				<img src="../../assets/pay/error.png" />
			</div>
			<div class="bianhao footer">{{$t('m.success172')}}</div>
			<div class="imgBox">
				<img src="../../assets/pay/success.png" />
			</div>
		</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "payType1",
		props: {
			msg: String,
		},
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {

			}
		},
	}
</script>

<style lang="less" scoped="scoped">
	.mainBpx {
		text-overflow: ellipsis;

		word-wrap: break-word;
		padding: 0.9375rem;
		text-align: center;

		.title {
			font-size: 18px;
			font-family: Helvetica-Light, Helvetica;
			font-weight: 300;
			color: #1f2a68;
			line-height: 20px;
		}

		.money {
			margin-top: 29px;
			font-size: 12px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 14px;
		}

		.num {
			margin-top: 5px;
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #FF006A;
			line-height: 19px;
		}

		.bianhao {
			margin-top: 5px;
			font-size: 12px;
			font-family: Helvetica;
			color: #1f2a68;
			line-height: 14px;
		}

		.haoma {
			margin-top: 5px;
			font-size: 16px;
			font-family: Helvetica-Bold, Helvetica;
			font-weight: bold;
			color: #1f2a68;
			line-height: 19px;
		}

		.footer {
			margin-top: 70px;
		}

		.imgBox {
			img {
				width: 6.25rem;
				margin-top: 13px;
				height: 6.25rem;
			}
		}

		
	}
</style>
