<template>
  <div class="validateTheInput">
    <!-- <HelloWorld></HelloWorld> -->
    <div class="orderForm" v-if="notFail">
      <div class="money">
        <span>{{ $t("m.validateTheInput.jine") }} :</span>
        <span
          >{{ orderInformation.data.amount }}
          {{ orderInformation.data.currency }}</span
        >
      </div>
      <div class="title common_margin_top">
        {{ $t("m.validateTheInput.Transaction") }}:
      </div>
      <div class="value common_margin_top">
        {{ orderInformation.data.order_time }}
      </div>
      <div class="title common_margin_top">
        {{ $t("m.validateTheInput.Reference") }}:
      </div>
      <div class="value common_margin_top">
        {{ orderInformation.data.order_id }}
      </div>
      <div class="halving_line"></div>
      <!-- 步骤 -->
      <div class="stepBox">
        <div class="step1" @click="change(1)">
          <div class="">{{ $t("m.validateTheInput.Log") }}</div>
          <img src="./imgs/step1.png" />
        </div>
        <div class="leidep">-----</div>
        <div class="step1" @click="change(2)">
          <div class="">{{ $t("m.validateTheInput.Verification") }}</div>
          <img src="./imgs/step2.png" v-if="state1 >= 2" />
          <img src="./imgs/stepb2.png" v-else />
        </div>
        <div class="leidep">-----</div>
        <div class="step1" @click="change(3)">
          <div class="">{{ $t("m.validateTheInput.Result") }}</div>
          <img src="./imgs/step3.png" v-if="state1 >= 3" />
          <img src="./imgs/stepb3.png" v-else />
        </div>
      </div>
      <!-- 手机端显示银行logo -->
      <div class="bankLogo isMobile">
        <img :src="orderInformation.data.channel_info.logo" />
      </div>
    </div>
    <div class="footerBox">
      <div class="pcBox">
        <!-- pc端显示银行logo -->
        <div class="bankLogo" v-if="notFail">
          <div class="addImgSty">
            <img :src="orderInformation.data.channel_info.logo" />
          </div>
          <!-- pc倒计时 -->
          <div
            class="leftImgTi pcTimer"
            style="margin-top: 10px; display: flex"
            v-if="reminder"
          >
            <img src="./imgs/time.png" />
            {{ $t("m.validateTheInput.Remain90")
            }}<span style="width: 5px"> </span>
            <van-count-down
              ref="countDown"
              :time="time"
              format="ss"
              style="color: #fd358a; margin-right: 5px"
              @finish="finish"
            />{{ $t("m.validateTheInput.Remain91") }}
          </div>
        </div>
        <!-- <div class="title isMobile">Đăng nhập</div> -->
        <!-- 用户名和密码输入  *2输入框状态 -->
        <!-- /////////////////////////////////// -->
        <div style="flex: 1" v-if="state1 == 1">
          <div class="inputBox">
            <div>
              <div class="img"><img src="./imgs/user.png" /></div>
              <input
                :placeholder="$t('m.validateTheInput.Account')"
                v-model="username"
              />
            </div>
            <div style="height: 1px; background-color: #c1c8d6"></div>
            <div>
              <div class="img">
                <img src="./imgs/lock.png" />
              </div>
              <input
                :placeholder="$t('m.validateTheInput.Password')"
                v-model="password"
                type="password"
              />
            </div>
          </div>
          <!-- <按钮   方式1 -->
          <div
            v-show="isWait"
            style="margin-top: 10px; text-align: center; margin-bottom: -15px"
          >
            <i class="el-icon-loading"></i>
          </div>
          <div class="btnBox btnChangeBox">
            <div
              class="btnOTP"
              v-for="(item, index) in orderInformation.data.channel_info
                .check_type"
              @click="payBankLogin(item.title, item)"
              v-bind:class="'btnChange' + index"
            >
              {{ item.title }} 
            </div>
            <!-- <div class="btnOTP" @click="payBankLogin('SMART')">Smart OTP</div> -->
          </div>
          <!-- <按钮   方式2 -->
          <!-- <div class="btnBox">
						<div class="btnOTP btnType2">SMS OTP</div>
					</div> -->
        </div>
        <!-- //////////////////////////////////// -->
        <!-- 用户名和密码输入  *1输入框状态 -->
        <div style="flex: 1" v-if="state1 == 2" class="council">
          <!-- <div>Thời gian còn lại:</div> -->
          <div style="text-align: center">
            {{ $t("m.validateTheInput.otpEnter") }}
            <span style="color: red">{{ userPinAll.data.userPin }}</span>
          </div>
          <div v-if="isQRpay == false || isTwoPay" style="text-align: center">
            <img :src="isQRpayQr" style="width: 150px" />
          </div>
          <input
            v-if="isQRpay || isTwoPay"
            v-model="otp"
            :placeholder="$t('m.validateTheInput.OTPcode')"
            @keyup="onInputKeyup()"
          />

          <!-- <按钮   方式2 -->
          <div class="btnBox" v-if="isQRpay || isTwoPay">
            <div class="btnOTP btnType2" @click="onBtnBox()">
              {{ $t("m.validateTheInput.next") }}
              <i
                v-if="goReq"
                class="el-icon-loading"
                style="color: #fff; margin-left: 5px"
              ></i>
            </div>
          </div>
        </div>
        <!-- ********************************************************* -->
        <!-- 支付状态load -->
        <!-- <div
          v-if="
            orderInformation.data.channel_info.name === 'PublicBank' &&
            status_type == 'WAIT' && messageHtml !== ''
          "
          class="Michigan"
        > -->

        <div v-if="stateNum == 5 && state1 == 5" class="Michigan">
          <div v-html="messageHtml"></div>
          <input
            type="text"
            style="height: 26px"
            class="form-control placeholder-no-fix"
            :placeholder="$t('m.registration_number')"
            name="USER_ANSWER"
            maxlength="40"
            size="40"
            v-model="USER_ANSWER"
          />
          <button
            type="submit"
            class="btn red"
            name="Next"
            data-role="none"
            @click="formClick"
            style="height: 32px; font-size: 13px; margin-left: 10px"
          >
            {{ $t("m.Confirm") }}
          </button>
        </div>
        
        <div v-if="stateNum == 6 && state1 == 6" class="Michigan">
          <div v-html="messageHtmlLogin"></div>
        </div>

        <div
          style="flex: 1"
          v-if="stateNum == 1 && state1 == 3"
          class="Michigan"
        >
          <div>
            {{ $t("m.validateTheInput.transaction") }}
          </div>
          <div>
            {{ $t("m.validateTheInput.Check") }}
          </div>
          <img src="./imgs/wait.gif" />
        </div>
        <!-- 支付状态失败 -->
        <div
          style="flex: 1"
          v-if="stateNum == 2 && state1 == 3"
          class="Michigan"
        >
          <img src="./imgs/fail.png" style="width: 104px !important" />
          <div v-html="failHtml"></div>
          <!-- <div>
            {{ $t("m.validateTheInput.failed502") }}
          </div> -->
          <!-- <div>
						{{$t("m.validateTheInput.failed502")}}
					</div> -->
        </div>
        <!-- 支付状态success-->
        <div
          style="flex: 1"
          v-if="stateNum == 3 && state1 == 3"
          class="Michigan"
        >
          <div>
            {{ $t("m.validateTheInput.waiting503") }}
          </div>
          <div>
            {{ $t("m.validateTheInput.success504") }}
          </div>
          <img src="./imgs/success.png" />
        </div>
        <!-- 支付状态超时-->
        <div
          style="flex: 1"
          v-if="stateNum == 4 && state1 == 3"
          class="Michigan"
        >
          <div>
            {{ $t("m.validateTheInput.jieguo505") }}
          </div>
          <!-- <div>Kiểm tra trạng thái giao dịch</div> -->
          <img src="./imgs/wait.png" />
          <div class="timeOutImg">
            {{ $t("m.validateTheInput.shibai506") }}
          </div>
        </div>
      </div>

      <!-- 剩余时间 -->
      <div class="time changeTime" v-if="reminder">
        <img src="./imgs/time.png" />
        {{ $t("m.validateTheInput.Remain90") }}<span style="width: 5px"> </span>
        <van-count-down
          ref="countDown"
          :time="time"
          format="ss"
          style="color: #fd358a; margin-right: 5px"
          @finish="finish"
        />
        {{ $t("m.validateTheInput.Remain91") }}
      </div>
      <!-- 提示 -->
      <div class="hint">
        <img src="../../assets/index/logo.png" style="width: 30%" />
        <div>
          <p>[ {{ $t("m.validateTheInput.Remark") }} ]</p>
          {{ $t("m.validateTheInput.rishi501") }}
        </div>
      </div>
      <!-- <div style="text-align: center;font-size: 14px;font-family: Helvetica;color: #6E7EA7;margin-top: 119px;">
				copyright 2021 RGPay</div> -->
    </div>
    <!-- <div style="flex: 1;height: 100%;"></div> -->
  </div>
</template>

<script>
import * as API from "@/api/pageRequest/home"; //引入API
import HelloWorld from "@/components/home/HelloWorld";
export default {
  name: "payType1",
  props: {
    msg: String,
  },
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      status_type: "", //判断是否为代表支付功能
      messageHtml: "", //当status_type为WAIT时所显示的代码
      messageHtmlLogin:"", //点击登录时状态当status_type为WAIT时所显示的代码
      failHtml: "", //失败后显示的文字
      USER_ANSWER: "",
      order_id: "",
      sign: "",
      url: "",
      state1: 1,
      input: "",
      stateNum: 4,
      // 订单信息
      orderInformation: {
        data: {
          amount: "1",
          channel_info: "",
          currency: "",
          order_id: "",
          order_time: "",
          timeout: "",
        },
      },
      userPinAll: {
        data: {
          userPin: "",
        },
      },
      time: 5 * 1000,
      notFail: true,
      // 用户名
      // username: "0794620182",
      username: "",
      // 密码
      // password: "Abc123456",
      password: "",
      // step2验证码
      otp: "",
      // 超时/支付失败/成功   倒计时控制器
      reminder: true,
      isQRpay: true,
      isQRpayQr: "",
      isTwoPay: false,
      goReq: false,
      isWait: false,
      check_type: "",
      check_type_two: "",
      serverLoginData: {},
      circulate_status: false,
      text: {
        code: 1,
        data: {
          callback_url: "https://www.rg-pay.com/pay",
          html: "Transaction Unsuccessful.",
        },
        msg: "OTP校验失败",
        status: "FAIL",
      },
      mo_text: {
        code: 0,
        data: { callback_url: "https://www.rg-pay.com/pay" },
        msg: "",
        status: "WAIT",
      },
      mo_text_two: {
        code: 0,
        data: "",
        msg: "OK",
        status: "SUSSSE",
      },
      login: {
        code: 0,
        data: {
          check_otp_url:
            "BxxxServlet?JSID=99816C332973B82FC20C74B04047C9BA8AA23B148597AE551EA0EB9817B28CFC&RDOName=BxxxFundTransfer&MethodName=transferOpenDiffUser",
          check_type: "SMART_OTP",
          timeout: 120000,
        },
        msg: "登录成功",
      },
    };
  },
  mounted() {
    let that = this;
    if (that.$route.query.order_id) {
      that.order_id = that.$route.query.order_id;
      // console.log(that.order_id)
    }
    if (that.$route.query.sign) {
      that.sign = that.$route.query.sign;
    }
    if (that.$route.query.lang) {
      that.$i18n.locale = that.$route.query.lang;
    }
    that.getOrder();
  },
  methods: {
    formClick() {
      if (!this.USER_ANSWER) {
        this.$toast.fail("");
        return;
      }
      this.otp = "666666";
      //this.mo_text = this.mo_text_two;
      this.bankVerify("test", "USER_ANSWER");
    },
    // 获取统一银行支付订单信息
    getOrder() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "vi");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      data.append("order_id", that.order_id);
      data.append("sign", that.sign);
      //获取统一银行支付订单信息
      API.getOrderData(data)
        .then((res) => {
          // that.orderInformation = res.data
          if (res.data.code == 1) {
            // ==1表示订单不可支付状态-显示状态为支付超时
            that.state1 = 3;
            that.stateNum = 4;
            that.notFail = false;
          } else {
            that.orderInformation = res.data;
            if (res.data.data.timeout && res.data.data.timeout != "") {
              that.time = res.data.data.timeout * 1000;
            } else {
              that.time = 600 * 1000;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    change(num) {
      // this.state1 = num
      // console.log(this.state1)
    },
    // 倒计时结束
    finish() {
      let that = this;
      // let check_type_server = sessionStorage.getItem('check_type_server');
      // if(check_type_server && (check_type_server == 'SMART_AFTER_OTP')) {
      //   return false;
      // }

      // ==表示订单不可支付状态-显示状态为支付超时

      that.state1 = 3;
      that.stateNum = 4;
      that.notFail = false;
      that.reminder = false;
      // window.location.href ="www.baidu.com"
      if (this.url != "") {
        window.location.href = this.url;
      }
      // setTimeout(function(){
      // 	// that.finish()
      // 	that.state1 = 1
      // 	that.reminder = true

      // },3000)
    },
    // 统一银行登录
    payBankLogin(val, item) {
      var a = val;
      var b = item;
      // postUrlData
      // console.log(item.value)
      // return
      var that = this;
      var value = item.value;

      if (value == "1") {
        value = "SMS";
      } else if (value == "2") {
        value = "SMART";
      } else {
        value = "SMS";
      }
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = localStorage.getItem("lang");
      // console.log(lang)
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      if (that.username.trim() == "") {
        this.$message.error({
          offset: 180,
          message: that.$t("m.validateTheInput.userNameNotHave"),
        });
        return;
      }
      if (that.password.trim() == "") {
        this.$message.error({
          offset: 180,
          message: that.$t("m.validateTheInput.ppp"),
        });
        return;
      }
      this.isWait = true;
      data.append("order_id", that.order_id);
      data.append("sign", that.sign);
      data.append("username", that.username);
      data.append("password", that.password);
      data.append("valid_type", value);
      // var timera = setInterval(function() {
      // 	that.payBankLogin(a, b)
      // }, 3000)
      API.getPayLogin(data)
        .then((res) => {
          if (res.data.code == 1) {
            that.isWait = false;
            // ==1表示订单不可支付状态-显示状态为支付超时
            // that.state1 = 3
            // that.stateNum = 4
            // that.notFail = false
          } else if (res.data.code == 0) {
            // that.isWait=false
            // console.log(res.data)
            // return
            // res.data.status='WAIT'
            //res.data.data.check_type = "OTP";
            if (res.data.data.status && res.data.data.status == "WAIT") {
              setTimeout(function () {
                that.payBankLogin(a, b);
              }, 3000);
            } else if(res.data.data.status_type && res.data.data.status_type == "WAIT") {
              setTimeout(function () {
                that.messageHtmlLogin = res.data.data.html;
              that.state1 = 6;
              that.stateNum = 6;
              }, 3000);
            }else {
              that.serverLoginData = res.data.data;
              that.isWait = false;
              that.userPinAll = res.data;
              that.check_type = res.data.data.check_type;
              that.circulate_status = true;
              // success
              // if (res.data.data.check_type == "OTP") {
              // 	that.otp = "QRCODE"
              // }
              that.state1 = 2;
              that.$refs.countDown.start();
              // that.stateNum = 4
              // 状态为0
              // that.orderInformation = res.data
              if (res.data.data.timeout && res.data.data.timeout != "") {
                that.time = res.data.data.timeout * 1000;
              } else {
                that.time = 60 * 1000;
              }
              if (
                res.data.data.check_type &&
                res.data.data.check_type == "QRCODE"
              ) {
                that.isQRpay = false;
                that.isQRpayQr = res.data.data.qrcode;
                that.bankVerify("test", res.data.data.check_type);
              } else if (
                res.data.data.check_type &&
                res.data.data.check_type == "SMART_OTP"
              ) {
                that.isQRpay = false;
                that.isQRpayQr = res.data.data.qrcode;
                that.bankVerify("test", res.data.data.check_type);
              } else if (
                res.data.data.check_type &&
                res.data.data.check_type == "SMART_AFTER_OTP"
              ) {
                that.isQRpay = false;
                //that.isQRpayQr = res.data.data.qrcode;
                that.check_type = "SMART_OTP";
                // 超时后转成OTP方式
                setTimeout(function () {
                  that.isTwoPay = true;
                  that.reminder = true;
                  that.state1 = 2;
                  that.check_type = "OTP";
                  if (res.data.data?.otp_timeout) {
                    res.data.data.timeout = res.data.data.otp_timeout;
                    that.time = res.data.data.timeout * 1000;
                    that.$refs.countDown.reset();
                    that.$refs.countDown.start();
                    delete res.data.data.otp_timeout;
                  }
                  that.circulate_status = false;
                  that.getsetOTP();
                }, that.time);
                that.bankVerify("test");
              } else if (
                res.data.data.check_type &&
                res.data.data.check_type == "QRCODE_AND_OTP"
              ) {
                that.isTwoPay = true;
                that.isQRpay = false;
                that.isQRpayQr = res.data.data.qrcode;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onBtnBox() {
      if (this.goReq) return false;
      this.bankVerify("mo", { event: "click" });
    },
    onInputKeyup() {
      this.circulate_status = false;
    },
    getsetOTP() {
      var that = this;
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = that.$i18n.locale;
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "vi");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }

      data.append("order_id", that.order_id);
      data.append("sign", that.sign);
      data.append("valid_type", that.serverLoginData.check_type);

      API.sendOTP(data)
        .then((res) => {
          // res.data.code=0
          console.log(res, "setOTP");
          if (res.data.code == 1) {
            that.$message.error({
              offset: 180,
              message: res.data.msg,
            });
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 统一银行OTP校验
    bankVerify(test, e) {
      var testVal = test;
      var that = this;
      that.goReq = true;
      console.log(test,"sdff")
      let data = new URLSearchParams();
      // 获取当前语言状态
      let lang = that.$i18n.locale;
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
        data.append("lang", "vi");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }

      if (
        that.orderInformation.data.channel_info.name === "PublicBank" &&
        e == "USER_ANSWER"
      ) {
        data.append("USER_ANSWER", that.USER_ANSWER);
      }
      data.append("order_id", that.order_id);
      data.append("sign", that.sign);

      if (that.otp.trim() != "") {
        data.append("otp", that.otp);
      }

      // if (that.text.code == 1) {
      //   // 	// ==1表示订单otp验证错误=支付失败
      //   console.log(that.text.status, "ddddd");
      //   if (that.text.status == "FAIL") {
      //     that.state1 = 3;
      //     that.stateNum = 2;
      //     that.reminder = false;
      //     that.failHtml = that.text.data.html;
      //     return;
      //   }
      // }

      // return;

      // console.log(this.mo_text, "data");

      // if (this.mo_text.code == 0) {
      //   // 验证成功
      //   this.status_type = this.mo_text.status;
      //   if (
      //     that.orderInformation.data.channel_info.name === "PublicBank" &&
      //     that.mo_text.data &&
      //     that.mo_text.data.html &&
      //     that.mo_text.data.html !== undefined
      //   ) {
      //     that.messageHtml = that.mo_text.data.html;
      //     that.state1 = 5;
      //     that.stateNum = 5;
      //     return;
      //   }

      //   if (that.mo_text.status === "WAIT") {
      //     if (testVal == "test" && that.circulate_status) {
      //       return that.bankVerify("test");
      //     } else if (testVal == "mo") {
      //       that.$message.error({
      //         offset: 180,
      //         message: this.mo_text.msg,
      //       });
      //     }
      //     if (
      //       res.data.data &&
      //       res.data.data.callback_url &&
      //       res.data.data.callback_ur != ""
      //     ) {
      //       that.url = that.mo_text.data.callback_url;
      //     }
      //     return;
      //   }

      //   that.state1 = 3;
      //   that.stateNum = 3;
      //   // 验证成功和失败/超时时-隐藏倒计时
      //   that.reminder = false;
      //   setTimeout(function () {
      //     if (
      //       this.mo_text.data.callback_url &&
      //       this.mo_text.data.callback_ur != ""
      //     ) {
      //       window.location.href = this.mo_text.data.callback_url;
      //     }
      //   }, 1000);
      // }
      // return;

      API.postCheckOTP(data)
        .then((res) => {
          if (res.data.code == 0) {
            // 验证成功
            that.status_type = res.data.status;
            if (
              // that.orderInformation.data.channel_info.name === "PublicBank" &&
              res.data.data &&
              res.data.data.html &&
              res.data.data.html !== undefined
            ) {
              that.messageHtml = res.data.data.html;
              that.state1 = 5;
              that.stateNum = 5;
              return;
            }

            // ==1表示订单otp验证错误=支付失败
            if (res.data.status === "WAIT") {
              if (testVal == "test" && that.circulate_status) {
                return that.bankVerify("test");
              } else if (testVal == "mo") {
                that.$message.error({
                  offset: 180,
                  message: res.data.msg,
                });
              }
              if (
                res.data.data &&
                res.data.data.callback_url &&
                res.data.data.callback_ur != ""
              ) {
                that.url = res.data.data.callback_url;
              }
              return;
            }

            that.state1 = 3;
            that.stateNum = 3;
            // 验证成功和失败/超时时-隐藏倒计时
            that.reminder = false;
            setTimeout(function () {
              if (
                res.data.data.callback_url &&
                res.data.data.callback_ur != ""
              ) {
                window.location.href = res.data.data.callback_url;
              }
            }, 10000);
          } else {
            //status == "FAIL"支付失败
            if (res.data.status == "FAIL") {
              that.state1 = 3;
              that.stateNum = 2;
              that.reminder = false;
              that.failHtml = res.data.msg;
              return;
            }
          }
          that.goReq = false;
        })
        .catch((err) => {
          that.state1 = 2;
          that.goReq = false;
        });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
// html,body,#app{
// 	height: 100vh;
// }
.pcBox {
  .btnChangeBox {
    div {
      flex: 1;
    }
  }

  .btnChange0 {
    margin-right: 5px;
  }

  .bankLogo {
    // background-color: #fff;
    width: 250px;
    overflow: hidden;

    // min-width: 200px;
    .addImgSty {
      box-sizing: border-box;
      // width: 80%;
      background-color: #fff;
      padding: 20px;
      // height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      height: 112px;

      img {
        max-width: 80%;
      }
    }

    display: none;
    margin-right: 20px;
  }
}

.Michigan {
  text-align: center !important;

  :nth-child(1) {
    font-size: 18px;
    font-family: Helvetica-Light, Helvetica;
    font-weight: 300;
    color: #1f2a68;
    text-align: center;
  }

  :nth-child(2) {
    font-size: 12px;
    font-family: Helvetica;
    color: #1f2a68;
    margin: 15px 0;
    text-align: center;
  }

  img {
    width: 104px;
  }

  .timeOutImg {
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #1f2a68;
    text-align: center;
  }
}

.council {
  :nth-child(1) {
    font-size: 16px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #1f2a68;
  }

  :nth-child(2) {
    font-size: 12px;
    font-family: Helvetica;
    color: #1f2a68;
    margin: 15px 0;
  }

  input {
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #c1c8d6;
    width: 100%;
    padding: 9px 20px;
    box-sizing: border-box;
    height: 45px;
    // margin-bottom: 44px;
  }
}

@media screen and (min-width: 1000px) {
  .pcTimer {
    align-items: center;
    justify-content: center;

    img {
      margin-right: 5px !important;
    }
  }

  .Michigan {
    :nth-child(1) {
      width: 245px;
      margin: 0 auto;
    }
  }

  .changeTime {
    display: none !important;
  }

  .bankLogo {
    display: none;
  }

  .isMobile {
    display: none;
  }

  .pcBox {
    max-width: 750px;
    display: flex;
    margin: 0 auto;

    .bankLogo {
      margin: 0;
      display: block !important;
      margin-right: 20px !important;

      img {
        // width: 250px !important;
        // margin-right: 20px;
      }
    }

    .inputBox {
      flex: 1;
      margin: 0 auto;
      height: 90px;
      // display: none !important;
    }
  }

  .btnBox {
    margin: 0 auto;

    .leftImgTi {
      display: block !important;
      margin-left: 100px;
      font-size: 16px;
      font-family: Helvetica;
      color: #1f2a68;

      img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }

    .btnOTP {
      min-width: 214px !important;
    }
  }
}

.validateTheInput {
  background-color: #f8f9ff;
  min-height: 100vh;
  padding-top: 50px;
  box-sizing: border-box;

  // display: flex;
  // flex-direction: column;
  // overflow-y: scroll;
  .leftImgTi {
    text-align: center;

    img {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .footerBox {
    // height: 100vh;
    // max-width: 750px;
    // margin: 0 auto;
    .btnType2 {
      width: 100% !important;
    }

    .hint {
      max-width: 750px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      margin-top: 65px;

      img {
        width: auto;
        height: 89px;
        margin-right: 25px;
      }

      div {
        font-size: 12px;
        font-family: Helvetica;
        color: #1f2a68;

        p {
          font-size: 20px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #1f2a68;
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }

    .btnBox {
      display: flex;
      justify-content: space-between;
      max-width: 750px;
      margin: 0 auto;

      .btnOTP {
        min-width: 150px;
        height: 45px;
        background: #1f2a68;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #ffffff;
      }

      .btnOTP:hover {
        opacity: 0.8;
      }
    }

    .time {
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 12px;
        margin-right: 10px;
      }

      span {
        font-size: 18px;
        color: #1f2a68;
      }
    }

    padding: 30px;
    background: #f8f9ff;

    .title {
      font-size: 18px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #1f2a68;
      line-height: 20px;
      text-align: center;
      margin-bottom: 30px;
    }

    .inputBox {
      border: 1px solid #c1c8d6;
      border-radius: 10px;
      overflow: hidden;
      margin: 0 auto;
      max-width: 750px;

      div {
        height: 45px;
        display: flex;

        .img {
          width: 44px;
          height: 45px;
          background: #4a5d87;
          background-color: #4a5d87;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 16px;
            width: auto;
          }
        }

        input {
          flex: 1;
          border: none;
          padding: 0 20px;
        }
      }
    }
  }

  div,
  span {
    text-align: justify;
    text-justify: newspaper;
    word-break: break-all;
  }

  .common_margin_top {
    margin-top: 5px;
  }

  .orderForm {
    padding: 0 25px;
    max-width: 750px;
    margin: 0 auto;

    .money {
      background: url(imgs/moneyBg.png);
      height: 35px;
      background-size: 100% 35px;
      display: flex;
      align-items: center;
      padding: 0 10px;

      span:nth-child(2) {
        font-size: 16px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #ffffff;
        line-height: 28px;
        margin-left: 10px;
      }

      span:nth-child(1) {
        font-size: 12px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 14px;
      }
    }

    .title {
      font-size: 12px;
      font-family: Helvetica;
      color: #1f2a68;
      line-height: 14px;
    }

    .value {
      font-size: 12px;
      font-family: Helvetica-Bold, Helvetica;
      font-weight: bold;
      color: #1f2a68;
      line-height: 14px;
    }

    .halving_line {
      width: 100%;
      height: 1px;
      background: #ebeeff;
      margin-top: 15px;
    }
  }

  .stepBox {
    display: flex;
    padding: 15px 0;

    .leidep {
      display: flex;
      align-items: center;
    }

    .step1 {
      flex: 1;
      text-align: center;

      div {
        text-align: center;
        font-size: 12px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #1f2a68;
        line-height: 14px;
      }

      p {
        display: flex;
        align-items: center;
      }

      img {
        width: 59px;
        height: auto;
        margin-top: 12px;
      }
    }
  }

  .bankLogo {
    text-align: center;
    margin: 12px 0;

    img {
      // width: 100%;
      height: auto;
      max-width: 100%;
    }
  }
}
</style>
