<template>
	<div class="validateTheInput">
		<HelloWorld></HelloWorld>
		<div class="orderForm">
			<div class="money">
				<span>Số tiền:</span>
				<span>40,000,000,000 RM</span>
			</div>
			<div class="title common_margin_top">Thời gian giao dịch:</div>
			<div class="value common_margin_top">2021-04-30 18:12:22</div>
			<div class="title common_margin_top">Số tham chiếu:</div>
			<div class="value common_margin_top">
				LIDA_on_ACB_89364782364284632LIDA_on_ACB_89364782364284632LIDA_on_ACB_8936478236428463</div>
			<div class="halving_line"></div>
			<!-- 步骤 -->
			<div class="stepBox">
				<div class="step1">
					<div class="">Dang nhap</div>
					<img src="./imgs/step1.png" />
				</div>
				<div class="leidep">-----</div>
				<div class="step1">
					<div class="">Ma xac thuc</div>
					<img src="./imgs/step2.png" />
				</div>
				<div class="leidep">-----</div>
				<div class="step1">
					<div class="">Ket qua</div>
					<img src="./imgs/step3.png" />
				</div>
			</div>
			<!-- 手机端显示银行logo -->
			<div class="bankLogo">
				<img src="../../assets/index/banner/16.png" />
			</div>
		</div>
		<div class="footerBox ">
			<div class="maxWatch paeizhi">
				<!-- pc显示银行logo -->
				<div class="bankLogo">
					<img src="../../assets/index/banner/16.png" />
				</div>
				<!-- 状态1 等待超时 -->
				<!-- <div class="rightVf">
					<div class="tishi ">Kết quả</div>
					<img src="./imgs/wait.png" />
					<div class="value">Không có thông tin nào được nhập</div>
				</div> -->
				<!-- 状态2 等待 -->
				<!-- <div  class="maxWatch">
					<div class="tishi loadBox">Vui lòng chờ giao dịch của bạn đang trong quá trình x</div>
					<div class="littlehint">Kiểm tra trạng thái giao dịch</div>
					<img src="./imgs/wait.gif" />
				</div> -->
				<!-- 状态2 失败 -->
				<!-- <div class="maxWatch">
					<div class="tishi ">Vui lòng chờ giao dịch của bạn đang trong quá trình x</div>
					<div class="littlehint">Kiểm tra trạng thái giao dịch</div>
					<img src="./imgs/fail.png" />
				</div> -->
				<!-- 状态2  成功 -->
				<div class="maxWatch">
					<div class="tishi ">Vui lòng chờ giao dịch của bạn đang trong quá trình x</div>
					<div class="littlehint">Kiểm tra trạng thái giao dịch</div>
					<img src="./imgs/success.png" />
				</div>
			</div>
			
			<!-- 提示 -->
			<div class="hint">
				<img src="./imgs/tpay.png" />
				<div>
					<p>[ Lưu ý] </p>
					Mỗi mã QR hoặc thông tin gửi tiền chỉ được sử dụng 1 lần, nếu sử dụng lần nữa có khả năng thất thoát
					giao dịch, cần thời gian 1 tuần để xử lý. Quý khách vui lòng thực hiện theo hướng dẫn nạp tiền để
					tránh xảy ra sai xót. Xin cảm ơn
				</div>
			</div>
		</div>
		
		<div style="text-align: center;font-size: 14px;font-family: Helvetica;color: #6E7EA7;padding-top: 119px;background-color: #F8F9FF;padding-bottom: 50px;">
			copyright 2023 RGPay</div>
	</div>
</template>

<script>
	import HelloWorld from "@/components/home/HelloWorld";
	export default {
		name: "payType1",
		props: {
			msg: String,
		},
		components: {
			HelloWorld: HelloWorld,
		},
		data() {
			return {

			}
		},
	}
</script>

<style lang="less" scoped="scoped">
	.footerBox{
		.bankLogo{
			display: none;
		}
	}
	
	@media screen and (min-width: 1000px) {
		.bankLogo{
			display: none;
		}
		.footerBox{
			.bankLogo{
				display: block !important;
				margin-top: 0 !important;
				img{
					width: 250px !important;
					margin-top: 0 !important;
				}
			}
			.rightVf{
				flex: 1;
			}
			.maxWatch{
				.loadBox{
					width: 250px;
				}
				.littlehint{
					margin-top: 15px;
				}
			}
		}
		.paeizhi{
			display: flex;
		}
	}
	.validateTheInput {
		.hint {
			max-width: 750px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
		
			img {
				width: auto;
				height: 89px;
				margin-right: 25px;
			}
		
			div {
				font-size: 12px;
				font-family: Helvetica;
				color: #1f2a68;
				text-align: left !important;
				p {
					font-size: 20px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #1f2a68;
					margin: 0;
					margin-bottom: 10px;
				}
		
			}
		}
		.footerBox {
			.maxWatch{
				max-width: 750px;
				margin: 0 auto;
			}
			.littlehint{
				font-size: 12px;
				font-family: Helvetica;
				color: #1f2a68;
			}
			.value {
				font-size: 16px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #1f2a68;
				margin-bottom: 30px;
			}

			.tishi {
				font-size: 18px;
				font-family: Helvetica-Light, Helvetica;
				font-weight: 300;
				color: #1f2a68;
				text-align: center;
			}

			div {
				text-align: center;

			}

			img {
				width: 104px;
				height: auto;
				margin: 13px 0;
			}

			.btnType2 {
				width: 100% !important;
			}

			.hint {
				display: flex;
				flex-direction: row;

				img {
					width: auto;
					height: 89px;
					margin-right: 25px;
				}

				div {
					font-size: 12px;
					font-family: Helvetica;
					color: #1f2a68;

					p {
						font-size: 20px;
						font-family: Helvetica-Bold, Helvetica;
						font-weight: bold;
						color: #1f2a68;
						margin: 0;
						margin-bottom: 10px;
					}

				}
			}

			.btnBox {
				display: flex;
				justify-content: space-between;

				.btnOTP {
					width: 150px;
					height: 45px;
					background: #1f2a68;
					border-radius: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #FFFFFF;
				}

				.btnOTP:hover {
					opacity: 0.8;
				}
			}

			.time {
				font-size: 12px;
				font-family: Helvetica;
				color: #1f2a68;
				text-align: center;
				margin-top: 15px;
				margin-bottom: 30px;

				span {
					font-size: 18px;
					color: #1f2a68;
				}
			}

			padding: 30px;
			background: #F8F9FF;

			.title {
				font-size: 18px;
				font-family: Helvetica-Light, Helvetica;
				font-weight: 300;
				color: #1f2a68;
				line-height: 20px;
				text-align: center;
				margin-bottom: 30px;
			}

			.inputBox {
				border: 1px solid #C1C8D6;
				border-radius: 10px;
				overflow: hidden;

				div {
					height: 45px;
					display: flex;

					.img {
						width: 44px;
						height: 45px;
						background: #4A5D87;
						background-color: #4A5D87;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							height: 16px;
							width: auto;
						}
					}

					input {
						flex: 1;
						border: none;
						padding: 0 20px;
					}
				}
			}
		}

		div,
		span {
			text-align: justify;
			text-justify: newspaper;
			word-break: break-all;
		}

		.common_margin_top {
			margin-top: 5px;
		}

		.orderForm {
			padding: 0 25px;
			max-width: 750px;
			margin: 0 auto;
			.money {
				background: url(imgs/moneyBg.png);
				height: 35px;
				background-size: 100% 35px;
				display: flex;
				align-items: center;
				padding: 0 10px;

				span:nth-child(2) {
					font-size: 16px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 28px;
					margin-left: 10px;
				}

				span:nth-child(1) {
					font-size: 12px;
					font-family: Helvetica;
					color: #FFFFFF;
					line-height: 14px;
				}
			}

			.title {
				font-size: 12px;
				font-family: Helvetica;
				color: #1f2a68;
				line-height: 14px;
			}

			.value {
				font-size: 12px;
				font-family: Helvetica-Bold, Helvetica;
				font-weight: bold;
				color: #1f2a68;
				line-height: 14px;
			}

			.halving_line {
				width: 100%;
				height: 1px;
				background: #EBEEFF;
				margin-top: 15px;
			}
		}

		.stepBox {
			display: flex;
			padding: 15px 0;

			.leidep {
				display: flex;
				align-items: center;
			}

			.step1 {
				flex: 1;
				text-align: center;

				div {
					text-align: center;
					font-size: 12px;
					font-family: Helvetica-Bold, Helvetica;
					font-weight: bold;
					color: #1f2a68;
					line-height: 14px;

				}

				p {
					display: flex;
					align-items: center;
				}

				img {
					width: 59px;
					height: auto;
					margin-top: 12px;
				}
			}
		}

		.bankLogo {
			text-align: center;
			margin: 12px 0;

			img {
				width: 150px;
				height: auto;
			}
		}
	}
</style>
