<template>
  <div class="download">
    <HelloWorld :myName="3"></HelloWorld>
    <div class="top">
      <div class="name">{{ $t("m.xiazai100") }}</div>
      <div class="hint">SDK DOWNLOAD</div>
    </div>
    <div class="mianBox">
      <div class="classify">
        <div class="pay cartoonBox" v-for="item in sdkUrl">
          <img :src="item.img" />
          <div class="name">{{ item.name }}</div>
          <a v-if="item.sdk" style="z-index: 10" :href="item.sdk">
            <div class="btn">
              <img src="../assets/download/down.png" />{{ $t("m.xiazai100") }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/pageRequest/home"; //����API
import HelloWorld from "@/components/home/HelloWorld";
export default {
  name: "Home",
  components: {
    HelloWorld: HelloWorld,
  },
  data() {
    return {
      isActive: 0,
      listImg: [
        {
          src: require("../assets/download/php.png"),
          name: "PHP  SDK",
          url: "",
        },
        {
          src: require("../assets/download/js.png"),
          name: "JAVA  SDK",
          url: "",
        },
        {
          src: require("../assets/download/h5.png"),
          name: "HTML5  SDK",
          url: "",
        },
        {
          src: require("../assets/download/net.png"),
          name: ".NET  SDK",
          url: "",
        },
        {
          src: require("../assets/download/ph.png"),
          name: "Python  SDK",
          url: "",
        },
      ],
      sdkUrl: "",
    };
  },
  mounted() {
    this.getSdk();
  },
  methods: {
    getSdk() {
      let data = new URLSearchParams();
      // ��ȡ��ǰ����״̬
      let lang = localStorage.getItem("lang");
      console.log(lang);
      if (lang == "vl-VN") {
        data.append("lang", "vi");
      } else if (lang == "zh-CN") {
        data.append("lang", "zh-cn");
      } else if (lang == "en-Us") {
		data.append("lang", "en-Us");
      } else if (lang == "ma-LN") {
        data.append("lang", "ma-LN");
      }
      let that = this;
      API.getSdk(data)
        .then((res) => {
          if (res.data.code == 0) {
            this.sdkUrl = res.data.data;
            console.log(res.data.data);
            // // this.$message.success('ע��ɹ�');
            // that.listImg[0].url = that.sdkUrl.php
            // that.listImg[1].url = that.sdkUrl.java
            // that.listImg[2].url = that.sdkUrl.hmtl5
            // that.listImg[3].url = that.sdkUrl.net
            // that.listImg[4].url = that.sdkUrl.python
            // for( var i in this.sdkUrl){
            // if(this.sdkUrl[i]==){

            // }
            // }
          } else {
            // console.log(res);
            // this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.cartoonBox:hover {
  transform: scale(1.25, 1.25);
  -webkit-transform: scale(1.25, 1.25);
  -moz-transform: scale(1.25, 1.25);
  -o-transform: scale(1.25, 1.25);
}

.download {
  .mianBox {
    background: #f8f9ff;

    .classify {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding-top: 25px;
      padding-bottom: 195px;

      .pay {
        width: 300px;
        height: 155px;
        background: #ffffff;
        box-shadow: 0px 5px 15px 0px rgba(55, 65, 93, 0.15);
        border-radius: 10px;
        margin-left: 50px;
        margin-top: 50px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;

        img {
          width: 200px;
          height: 120px;
          position: absolute;
          right: 0;
          top: 0;
        }

        .name {
          z-index: 9;
          font-size: 20px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #1f2a68;
          line-height: 28px;
        }

        .btn {
          z-index: 9;
          width: 200px;
          height: 40px;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #7180a1;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          font-family: Helvetica-Bold, Helvetica;
          font-weight: bold;
          color: #6e7ea7;
          line-height: 28px;
          cursor: pointer;

          img {
            position: relative;
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
        }

        .btn:hover {
          border: 1px solid #4393f9;
        }
      }
    }
  }

  .top {
    width: 100%;
    height: 200px;
    background-image: url(../assets/download/bg.png);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .name {
      font-size: 35px;
      font-family: Helvetica-Light, Helvetica;
      font-weight: 300;
      color: #ffffff;
      line-height: 42px;
    }

    .hint {
      font-size: 20px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #ffffff;
      line-height: 28px;
      letter-spacing: 2px;
    }
  }
}
</style>
