var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutUs"},[_c('HelloWorld',{attrs:{"myName":6}}),_c('div',{staticClass:"top"},[_c('div',{staticClass:"aboutName1"},[_vm._v(_vm._s(_vm.$t("m.women102")))]),_c('div',{staticClass:"aboutName2"},[_vm._v("ABOUT US")])]),_c('div',{staticClass:"aboutName11"},[_vm._v(_vm._s(_vm.$t("m.lianxi103")))]),_c('div',{staticClass:"aboutName22"},[_vm._v("CONTACT US")]),_c('div',{staticClass:"main"},[_c('div',{staticStyle:{"height":"75px"}}),_c('div',{staticClass:"allBox main1"},[_c('div',{staticClass:"list widthList"},[_c('div',{staticClass:"emailBox"},[_vm._v(" "+_vm._s(_vm.$t("m.lainxi107"))+"     "),_vm._m(0)]),_vm._m(1),_vm._m(2)]),_vm._m(3)])]),_c('div',{staticClass:"aboutName11"},[_vm._v(_vm._s(_vm.$t("m.hezuo110")))]),_c('div',{staticClass:"aboutName22"},[_vm._v("CHANEL COOPERATION")]),_c('div',{staticClass:"main collaborate"},[_c('div',{staticClass:"allBox main1"},[_c('div',{staticClass:"left wow slideInUp"},[_vm._m(4),_c('div',{staticClass:"matter"},[_vm._v(_vm._s(_vm.$t("m.wenan112")))]),_c('a',{attrs:{"href":'mailto:' + _vm.url,"id":"sendMail"}},[_c('div',{staticClass:"btn"},[_vm._v(_vm._s(_vm.$t("m.lianxi113")))])])]),_c('div',{staticClass:"right wow slideInUp"},[_vm._m(5),_c('div',{staticClass:"matter"},[_vm._v(_vm._s(_vm.$t("m.fuwu116")))]),_c('a',{attrs:{"href":'mailto:' + _vm.url,"id":"sendMail"}},[_c('div',{staticClass:"btn"},[_vm._v(_vm._s(_vm.$t("m.kefu117")))])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"color":"#1f2a68"},attrs:{"id":""}},[_c('span',[_vm._v("yiichiiping@hotmail.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"emailBox"},[_vm._v(" Telegram:     "),_c('a',{staticStyle:{"color":"#1f2a68"}},[_c('span',[_c('a',{staticStyle:{"color":"rgb(13, 38, 95)"},attrs:{"s":""}},[_vm._v("A9admin_99")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"emailBox"},[_vm._v(" Skype:     "),_c('a',{staticStyle:{"color":"#1f2a68"}},[_c('span',[_c('a',{staticStyle:{"color":"#1f2a68"}},[_vm._v("live:.cid.c15c361aa206fac7")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeBox codeVal"},[_c('img',{staticStyle:{"width":"100%","height":"100%","border-radius":"12px"},attrs:{"src":require("../assets/index/skype.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('img',{attrs:{"src":require("../assets/aboutUs/kefu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"codeBox"},[_c('img',{attrs:{"src":require("../assets/aboutUs/shoushou.png")}})])
}]

export { render, staticRenderFns }