<template>
	<div class="home">
		<!-- <HelloWorld></HelloWorld> -->
		<!-- <el-button type="primary" @click="onSubmit">点击</el-button>
	<span><a href="">{{$t('m.findMusic')}}</a></span> -->
	</div>

</template>

<script>
	import * as API from '@/api/pageRequest/home'; //引入API
	import HelloWorld from '@/components/home/HelloWorld'
	export default {
		name: "Home",
		components: {
			'HelloWorld': HelloWorld,
		},
		data() {
			return {
				producerSn: '',
				blocCode: 'FXFT_0591591000',
				userids: '775',
				path: ""
			}
		},
		mounted() {
			var indexJump=this.$route.query.jumpIndex
			if(indexJump){
				this.$router.push({path:'product', query: {jumpIndex: indexJump}})
				return
			}
			this.path = localStorage.getItem('userId');
			this.$router.push({
				name: 'LoginAndRegister',
				params: {
					userId: this.path
				}
			})
		},
		methods: {
			//    getHome(){
			//      let data = new URLSearchParams()
			//      if(this.producerSn){
			//        data.append('producerSn',this.producerSn)
			//      }
			//      API.getHome(data).then(res => {
			//        if(res.data.code == 200){
			//          console.log(res);
			//        }else{
			//          console.log(res.msg);
			//        }
			//      }).catch(err => {
			//        console.log(err.msg);
			//      })
			//    },
			//    postHome(){
			//      let data = new URLSearchParams()
			//      data.append('blocCode',this.blocCode)
			//      data.append('userids',this.userids)
			//      if(this.producerSn){
			//        data.append('producerSn',this.producerSn)
			//      }
			//      API.postHome(data).then(res => {
			//        console.log(res);
			//      }).catch(err => {
			//        console.log(err);
			//      })
			//    },
			// /** 语言切换 */
			// onSubmit() {
			//   // let self_current_lang = this.langForm.radio
			//   let self_current_lang ="ja-JP"
			//   if (self_current_lang) {
			//     // 切换系统语言
			//     this.$i18n.locale = self_current_lang
			//     // 将新语言保存到全局状态管理
			//     this.$store.dispatch('update_current_lang', self_current_lang)
			//     // 关闭语言询问框
			//     this.chooseLangDialogVisible = false
			//     // 成功提示
			//     this.$message.success('系统语言切换成功！')
			//   } else {
			//     this.$message.warning('没有你想要的语言吗，选一个吧！')
			//   }
			// },
		},
		created() {
			// this.getHome()
		}
	};
</script>
